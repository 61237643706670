// contexts/AuthContext.js
import { createContext, useContext, useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import { jwtDecode } from "jwt-decode"; // se necessário

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [cookies, setCookie, removeCookie] = useCookies(["AuthToken", "UserType", "UserId", "UserName"]);
  const [user, setUser] = useState({
    authToken: cookies.AuthToken || null,
    userType: cookies.UserType || null,
    userId: cookies.UserId || null,
    userName: cookies.UserName || null,
  });

  // Sincronizar estado local com cookies
  useEffect(() => {
    setUser({
      authToken: cookies.AuthToken,
      userType: cookies.UserType,
      userId: cookies.UserId,
      userName: cookies.UserName,
    });
  }, [cookies]);

  const setAuthData = (token) => {
    const decodedToken = jwtDecode(token); // Se precisar decodificar o token
    setCookie("AuthToken", token, { path: "/", secure: true });
    setCookie("UserId", decodedToken.UserId, { path: "/", secure: true });
    setCookie("UserType", decodedToken.UserType, { path: "/", secure: true });
    setCookie("UserName", decodedToken.UserName, { path: "/", secure: true });

    setUser({
      authToken: token,
      userId: decodedToken.UserId,
      userType: decodedToken.UserType,
      userName: decodedToken.UserName,
    });
  };

  const logout = () => {
    removeCookie("AuthToken", { path: "/" });
    removeCookie("UserType", { path: "/" });
    removeCookie("UserId", { path: "/" });
    removeCookie("UserName", { path: "/" });
    removeCookie("SelectedJobId", { path: "/" });
    removeCookie("SelectedJobTitle", { path: "/" }); 
    setUser({ authToken: null, userType: null, userId: null, userName: null });
  };

  return (
    <AuthContext.Provider value={{ user, setUser, setAuthData, logout }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);

import { AuthProvider } from "./contexts/AuthContext";
import Home from "./pages/Home";
import Dashboard from "./pages/Dashboard";
import Onboarding from "./pages/Onboarding";
import OnCadasterJob from "./pages/OnCadasterJob";
import OnBoardingEmpresa from "./pages/OnboardingEmpresa";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import ProtectedRoute from "./components/ProtectedRoute";
import { JobProvider } from "./contexts/JobContext"; // Importe o JobProvider

const App = () => {
  return (
    <AuthProvider>
      <JobProvider>
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/dashboard" element={<ProtectedRoute> <Dashboard /> </ProtectedRoute>} />
            <Route path="/onBoarding" element={<ProtectedRoute allowedUserTypes={["candidato"]}> <Onboarding /></ProtectedRoute>} />
            <Route path="/onBoardingEmpresa" element={<ProtectedRoute allowedUserTypes={["empresa"]}> <OnBoardingEmpresa /></ProtectedRoute>} />
            <Route path="/onCadasterJob" element={<ProtectedRoute allowedUserTypes={["empresa"]}> <OnCadasterJob /></ProtectedRoute>} />
            <Route path="/onCadasterJob/:jobId" element={<ProtectedRoute allowedUserTypes={["empresa"]}> <OnCadasterJob /></ProtectedRoute>} />
          </Routes>
        </BrowserRouter>
      </JobProvider>
    </AuthProvider>
  );
};

export default App;

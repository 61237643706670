import axios from "axios";

// API Base URL
const API_URL = process.env.REACT_APP_API_BASE_URL;

// Função para buscar dados de vagas para candidatos
export const fetchJobListings = async (token) => {
  try {
    const response = await axios.get(`${API_URL}/vaga`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    throw new Error("Erro ao buscar vagas: " + error.message);
  }
};

// Função para buscar candidatos para empresas
export const fetchCandidates = async (token) => {
  try {
    const response = await axios.get(`${API_URL}/candidato`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    throw new Error("Erro ao buscar candidatos: " + error.message);
  }
};

// Função para buscar conexões de candidato ou empresa
export const fetchConnections = async (token, userType, userId) => {
  try {
    const response = await axios.get(`${API_URL}/correspondencia/correspondencias-confirmadas/${userType}/${userId}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    throw new Error("Erro ao buscar conexões: " + error.message);
  }
};

export const fetchConnectionsByType = async (token, userType, userId) => {
  try {
    const response = await axios.get(`${API_URL}/correspondencia/${userType}/${userId}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    throw new Error("Erro ao buscar conexões: " + error.message);
  }
};

export const acceptConnection = async (token, payload) => {
  try {
    const response = await axios.post(`${API_URL}/correspondencia/aceitar-recusar`, payload, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response;
  } catch (error) {
    throw new Error("Erro ao aceitar conexão: " + error.message);
  }
};

export const rejectConnection = async (token, payload) => {
  try {
    const response = await axios.post(`${API_URL}/correspondencia/aceitar-recusar`, payload, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response;
  } catch (error) {
    throw new Error("Erro ao recusar conexão: " + error.message);
  }
};


// Função para buscar dados do usuário
export const getInfo = async (userType, id, authToken) => {
  try {
    if (userType === "candidato") {
      const candidateResponse = await axios.get(`${API_URL}/candidato/${id}`, { headers: { Authorization: `Bearer ${authToken}`}});
      return candidateResponse.data;  // Dados do candidato
    } else if (userType === "empresa") {
      const companyResponse = await axios.get(`${API_URL}/vaga/${id}`, { headers: { Authorization: `Bearer ${authToken}`}});
      return companyResponse.data;  // Dados da empresa
    }
  } catch (error) {
    console.error("Erro ao buscar dados do candidato:", error);
    throw error;
  }
};


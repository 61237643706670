import Nav from "../components/Nav";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from '../contexts/AuthContext';
import { fetchCandidatoData, updateCandidatoData } from '../services/candidatoService';

const OnBoarding = () => {
  const { user } = useAuth();
  const navigate = useNavigate();

  const [editIndex, setEditIndex] = useState(null);
  const [editIndexExperiencia, setEditIndexExperiencia] = useState(null);

  const [buttonText, setButtonText] = useState('SALVAR INFORMAÇÕES');
  const [isFormChanged, setIsFormChanged] = useState(false);

  // Verificação para garantir que o usuário seja um candidato
  useEffect(() => {
    if (!user || user.userType !== 'candidato') {
      // Redireciona para o login ou página apropriada caso não seja uma empresa
      navigate('/login');
    }
  }, [user, navigate]);

  useEffect(() => {
    if (isFormChanged) {
      setButtonText('SALVAR INFORMAÇÕES');
    }
  }, [isFormChanged]);


  const [formData, setFormData] = useState({
    nome: '',
    email: '',
    cpf: '',
    data_nascimento: '',
    sexo: '',
    cep: '',
    logradouro: '',
    numero: '',
    complemento: '',
    bairro: '',
    cidade: '',
    uf: '',
    pais: '',
    telefone: '',
    sobre: '',
    linkedin: '',
    links: [],
    formacoes: [],
    habilidades: [],
    experiencias: [],
    nivel_escolaridade_candidato: 'VAZIO',
    tipo_habilidade: '',
    nivel_conhecimento: 0,
    nome_empresa: '',
    cargo: '',
    tipo_cargo: 'CLT',
    data_entrada: '',
    data_saida: '',
    emprego_atual: false,
  });


  // Função para carregar os dados do candidato
  useEffect(() => {
    const loadCandidatoData = async () => {
      if (user.userId && user.authToken) {
        try {
          const data = await fetchCandidatoData(user.userId, user.authToken);
          setFormData({
            nome: data.nome || "",
            email: data.email || "",
            cpf: data.cpf || "",
            data_nascimento: data.data_nascimento || "",
            sexo: data.sexo || "",
            telefone: data.telefone || "",
            sobre: data.sobre || "",
            linkedin: data.linkedin || "",
            links: data.links || "",
            cep: data.cep || "",
            logradouro: data.logradouro || "",
            numero: data.numero || "",
            complemento: data.complemento || "",
            bairro: data.bairro || "",
            cidade: data.cidade || "",
            uf: data.uf || "",
            pais: data.pais || "",
            formacoes: data.formacoes || [],
            habilidades: data.habilidades || [],
            experiencias: data.experiencias || []
          });
        } catch (error) {
          console.error('Erro ao carregar os dados do candidato:', error);
        }
      }
    };
    loadCandidatoData();
  }, [user.userId, user.authToken]);


  // Função de envio do formulário
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await updateCandidatoData(formData, user.authToken);
      if (response.status === 200)
        setButtonText('Ir para Dashboard');
      setIsFormChanged(false);
    } catch (err) {
      console.error('Erro ao atualizar os dados do candidato:', err);
    }
  };

  // Função para navegação, se o botão for clicado novamente
  const handleButtonClick = () => {
    if (buttonText === 'Ir para Dashboard') {
      navigate('/dashboard');  // Navega para o dashboard
    }
  };

  // Função para monitorar alterações no formulário
  const handleChange = (e) => {
    const value = e.target.type === "checkbox" ? e.target.checked : e.target.value;
    const name = e.target.name;
    setFormData((prevData) => {
      const updatedData = { ...prevData, [name]: value };
      // Verificar se houve alteração nos dados
      setIsFormChanged(JSON.stringify(updatedData) !== JSON.stringify(formData));
      return updatedData;
    });
  };


  const handleChangeExperiencia = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData((prevData) => {
      const updatedData = { ...prevData, [name]: type === "checkbox" ? checked : value };
      // Verificar se houve alteração nos dados
      setIsFormChanged(JSON.stringify(updatedData) !== JSON.stringify(formData));
      return updatedData;
    });
  };

  const addLink = () => {
    const newLink = formData.linkInput.trim();
    if (newLink) {
      setFormData((prevState) => ({
        ...prevState,
        links: [...(prevState.links || []), newLink],
        linkInput: "",
      }));
    }
  };

  const deleteLink = (index) => {
    setFormData((prevState) => ({
      ...prevState,
      links: prevState.links.filter((_, i) => i !== index), // Remove o link do array
    }));
  };

  const addFormacao = () => {
    const newFormacao = {
      nivel_escolaridade_candidato: formData.nivel_escolaridade_candidato,
      curso_candidato: formData.curso_candidato,
      instituicao_candidato: formData.instituicao_candidato,
      data_inicio: formData.data_inicio,
      data_termino: formData.data_termino,
      estado_estudo: formData.estado_estudo,
    };

    setFormData((prevState) => {
      const updatedFormacoes = prevState.formacoes || [];

      if (editIndex !== null) {
        updatedFormacoes[editIndex] = newFormacao;
        setEditIndex(null);
      } else {
        updatedFormacoes.push(newFormacao);
      }

      return { ...prevState, formacoes: updatedFormacoes };
    });

    setFormData((prevState) => ({
      ...prevState,
      nivel_escolaridade_candidato: "",
      curso_candidato: "",
      instituicao_candidato: "",
      data_inicio: "",
      data_termino: "",
      estado_estudo: "",
    }));
  };

  const editFormacao = (index, e) => {
    e.preventDefault();
    setFormData((prevState) => {
      const formacaoToEdit = prevState.formacoes[index];

      return {
        ...prevState,
        nivel_escolaridade_candidato:
          formacaoToEdit.nivel_escolaridade_candidato,
        curso_candidato: formacaoToEdit.curso_candidato,
        instituicao_candidato: formacaoToEdit.instituicao_candidato,
        data_inicio: formacaoToEdit.data_inicio,
        data_termino: formacaoToEdit.data_termino,
        estado_estudo: formacaoToEdit.estado_estudo,
      };
    });
    setEditIndex(index);
  };

  const deleteFormacao = (index) => {
    setFormData((prevState) => ({
      ...prevState,
      formacoes: (prevState.formacoes || []).filter((_, i) => i !== index),
    }));
  };

  const addHabilidade = () => {
    const newHabilidade = {
      tipo_habilidade: formData.tipo_habilidade,
      habilidade_candidato: formData.habilidade_candidato,
      nivel_conhecimento: formData.nivel_conhecimento,
    };

    if (editIndex !== null) {
      const habilidadesAtualizadas = formData.habilidades.map(
        (habilidade, index) =>
          index === editIndex ? newHabilidade : habilidade
      );

      setFormData((prevState) => ({
        ...prevState,
        habilidades: habilidadesAtualizadas,
      }));

      setEditIndex(null);
    } else {
      setFormData((prevState) => ({
        ...prevState,
        habilidades: [...prevState.habilidades, newHabilidade],
      }));
    }

    setFormData((prevState) => ({
      ...prevState,
      tipo_habilidade: "",
      habilidade_candidato: "",
      nivel_conhecimento: 0,
    }));
  };

  const editHabilidade = (index, e) => {
    e.preventDefault();
    const habilidadeParaEditar = formData.habilidades[index];

    setFormData((prevState) => ({
      ...prevState,
      tipo_habilidade: habilidadeParaEditar.tipo_habilidade,
      habilidade_candidato: habilidadeParaEditar.habilidade_candidato,
      nivel_conhecimento: habilidadeParaEditar.nivel_conhecimento,
    }));

    setEditIndex(index);
  };

  const deleteHabilidade = (index) => {
    const novasHabilidades = formData.habilidades.filter((_, i) => i !== index);
    setFormData((prevState) => ({ ...prevState, habilidades: novasHabilidades }));
  };



  const addExperiencia = () => {
    const novaExperiencia = {
      nome_empresa: formData.nome_empresa,
      cargo: formData.cargo,
      tipo_cargo: formData.tipo_cargo,
      data_entrada: formData.data_entrada,
      data_saida: formData.data_saida,
      emprego_atual: formData.emprego_atual,
    };

    setFormData((prevState) => {
      const updatedExperiencias = prevState.experiencias || [];

      if (editIndexExperiencia !== null) {
        updatedExperiencias[editIndexExperiencia] = novaExperiencia;
        setEditIndexExperiencia(null);
      } else {
        updatedExperiencias.push(novaExperiencia);
      }

      return { ...prevState, experiencias: updatedExperiencias };
    });

    setFormData((prevState) => ({
      ...prevState,
      nome_empresa: "",
      cargo: "",
      tipo_cargo: "",
      data_entrada: "",
      data_saida: "",
      emprego_atual: "",
    }));
  };


  const editExperiencia = (index, e) => {
    e.preventDefault();
    const experienciaEditada = formData.experiencias[index];

    setFormData((prevState) => ({
      ...prevState,
      nome_empresa: experienciaEditada.nome_empresa,
      cargo: experienciaEditada.cargo,
      tipo_cargo: experienciaEditada.tipo_cargo,
      data_entrada: experienciaEditada.data_entrada,
      data_saida: experienciaEditada.data_saida,
      emprego_atual: experienciaEditada.emprego_atual,
    }));
    setEditIndexExperiencia(index);
  };

  const deleteExperiencia = (index) => {
    const updatedExperiencias = formData.experiencias.filter((_, i) => i !== index);
    setFormData((prevState) => ({ ...prevState, experiencias: updatedExperiencias }));
  };

  const TipoHabilidade = {
    VAZIO: "Selecione",
    TECNICA: "Técnica",
    COMPORTAMENTAL: "Comportamental",
    CERTIFICACAO: "Certificação",
  };

  const NivelEscolaridade = {
    VAZIO: "Selecione",
    ENSINO_FUNDAMENTAL: "Ensino Fundamental",
    ENSINO_MEDIO: "Ensino Médio",
    CURSO_TECNICO: "Curso Técnico",
    ENSINO_SUPERIOR: "Ensino Superior",
    POS_GRADUACAO: "Pós-Graduação",
    MESTRADO: "Mestrado",
    DOUTORADO: "Doutorado",
    POS_DOUTORADO: "Pós-Doutorado",
    CURSO_EXTENSAO: "Curso Profissionalizante",
  };

  const EstadoEscolaridade = {
    VAZIO: "Selecione",
    CURSANDO: "Cursando",
    INTERROMPIDO: "Interrompido",
    COMPLETO: "Completo",
  };

  const renderStars = (nivel) => {
    return [...Array(5)].map((_, index) => {
      const value = index + 1;
      return (
        <span
          key={index}
          style={{
            color: nivel >= value ? "crimson" : "gray",
            cursor: "pointer",
          }}
        >
          {value % 1 === 0 ? "★" : "☆"}
        </span>
      );
    });
  };

  const buscarEnderecoPorCep = async (cep) => {
    try {
      const response = await fetch(`https://viacep.com.br/ws/${cep}/json/`);
      const data = await response.json();

      if (data.erro) {
        alert("CEP não encontrado");
        return;
      }

      // Preenche os campos do formulário com os dados do retorno
      setFormData({
        ...formData,
        cep: data.cep,
        logradouro: data.logradouro,
        complemento: data.complemento,
        bairro: data.bairro,
        cidade: data.localidade,
        estado: data.estado,
        uf: data.uf,
        pais: "Brasil",
      });
    } catch (error) {
      console.error("Erro ao buscar o CEP:", error);
    }
  };

  // Exemplo de uso: Chamar a função ao digitar o CEP
  const handleCepChange = (e) => {
    const cep = e.target.value;
    setFormData({ ...formData, cep: cep });
    if (cep.length === 8) buscarEnderecoPorCep(cep);
  };

  return (
    <>
      <Nav setShowModal={() => { }} showModal={false} />
      <div className="scrolling-class">
        <div className="onboarding">
          <h1>DADOS DA CONTA</h1>
          <h2>Informações pessoais</h2>
          <form onSubmit={handleSubmit}>
            <section>
              <label htmlFor="nome">Nome Completo</label>
              <input id="nome" type="text" name="nome" placeholder="Nome do candidato" required={true} value={formData.nome} onChange={handleChange} />
              <label htmlFor="nome">E-mail</label>
              <input id="email" type="email" name="email" placeholder="E-mail" required={true} value={formData.email} onChange={handleChange} />
              <label htmlFor="cpf">CPF</label>
              <input id="cpf" type="text" name="cpf" placeholder="000.000.000-00" required={true} value={formData.cpf} onChange={handleChange} />
              <label htmlFor="data_nascimento">Data de Nascimento</label>
              <input id="data_nascimento" type="date" name="data_nascimento" required={true} value={formData.data_nascimento} onChange={(e) => handleChange(e)} />
              <label>Gênero</label>
              <div className="multiple-input-container">
                <input id="man-gender-identity" type="radio" name="sexo" value="Masculino" required={true} checked={formData.sexo === "Masculino"} onChange={handleChange} />
                <label htmlFor="man-gender-identity">Masculino</label>
                <input id="woman-gender-identity" type="radio" name="sexo" value="Feminino" required={true} checked={formData.sexo === "Feminino"} onChange={handleChange} />
                <label htmlFor="woman-gender-identity">Feminino</label>
                <input id="outro-gender-identity" type="radio" name="sexo" value="Outro" required={true} checked={formData.sexo === "Outro"} onChange={handleChange} />
                <label htmlFor="outro-gender-identity">Outro</label>
              </div>

              <label htmlFor="endereco">Endereço</label>
              <div className="multiple-input-container">
                <input id="cep" type="text" name="cep" placeholder="CEP" value={formData.cep} onChange={handleCepChange} />
                <input id="logradouro" type="text" name="logradouro" placeholder="Logradouro" value={formData.logradouro} onChange={handleChange} />
                <input className="div-number" id="numero" type="number" name="numero" placeholder="Nº" required={true} value={formData.numero} onChange={handleChange} />
                <input id="complemento" type="text" name="complemento" placeholder="Complemento" value={formData.complemento} onChange={handleChange} />
              </div>
              <div className="multiple-input-container">
                <input id="bairro" type="text" name="bairro" placeholder="Bairro" value={formData.bairro} onChange={handleChange} />
                <input id="cidade" type="text" name="cidade" placeholder="Cidade" required={true} value={formData.cidade} onChange={handleChange} />
                <input id="uf" type="text" name="uf" placeholder="UF" value={formData.uf} onChange={handleChange} />
                <input id="pais" type="text" name="pais" placeholder="País" required={true} value={formData.pais} onChange={handleChange} />
              </div>

              <label htmlFor="telefone">Telefone</label>
              <input id="telefone" type="text" name="telefone" placeholder="(XX) XXXXX-XXXX" required={false} value={formData.telefone} onChange={handleChange} />
              <label htmlFor="sobre">Sobre</label>
              <textarea id="sobre" name="sobre" placeholder="Conte um pouco sobre você!" required={false} value={formData.sobre} onChange={handleChange} />
              <label htmlFor="linkedin">LinkedIn</label>
              <input id="linkedin" type="text" name="linkedin" placeholder="https://www.linkedin.com/in/exemplo" required={false} value={formData.linkedin} onChange={handleChange} />
              <h3>Links</h3>
              <div>
                <div className="multiple-input-container">
                  <input id="linkInput" type="text" name="linkInput" placeholder="Digite o link" value={formData.linkInput} onChange={handleChange} />
                  <button type="submit" onClick={addLink}>
                    {" "}
                    Adicionar Link
                  </button>
                </div>
                <ul>
                  {Array.isArray(formData.links) && formData.links.length > 0 ? (formData.links.map((link, index) => (
                    <li key={index}>
                      <span>{link}</span>
                      <button onClick={() => deleteLink(index)}>
                        Excluir
                      </button>
                    </li>
                  ))) : (<li>Nenhum link cadastrado.</li>)}
                </ul>
                <br></br>
                <br></br>
              </div>
              <button type=" submit" className="submit-forms" id="center-button" onClick={handleButtonClick}>
                {buttonText}
              </button>
            </section>
          </form>
          <br />
          <br />
          <h2>Formação Acadêmica</h2>
          <form onSubmit={handleSubmit}>
            <section>
              <label htmlFor="nivel_escolaridade_candidato">
                Nível de Escolaridade
              </label>
              <select id="nivel_escolaridade_candidato" name="nivel_escolaridade_candidato" value={formData.nivel_escolaridade_candidato || 'VAZIO'} onChange={handleChange}>
                <option value="VAZIO">Selecione</option>
                <option value="ENSINO_FUNDAMENTAL">Ensino Fundamental</option>
                <option value="ENSINO_MEDIO">Ensino Médio</option>
                <option value="CURSO_TECNICO">Curso Técnico</option>
                <option value="ENSINO_SUPERIOR">Ensino Superior</option>
                <option value="POS_GRADUACAO">Pós-Graduação</option>
                <option value="MESTRADO">Mestrado</option>
                <option value="DOUTORADO">Doutorado</option>
                <option value="POS_DOUTORADO">Pós-Doutorado</option>
                <option value="CURSO_EXTENSAO">Curso Profissionalizante</option>
              </select>
              <label htmlFor="curso_candidato">Curso</label>
              <input id="curso_candidato" type="text" name="curso_candidato" placeholder="Exemplo: Sistemas de Informação" value={formData.curso_candidato} onChange={handleChange} />
              <label htmlFor="instituicao_candidato">
                Instituição de Ensino
              </label>
              <input id="instituicao_candidato" type="text" name="instituicao_candidato" placeholder="Nome da instituição onde estudou" value={formData.instituicao_candidato} onChange={handleChange} />
              <div className="multiple-input-container">
                <p id="center-p">Data de Início</p>
                <input id="data_inicio" type="month" name="data_inicio" value={formData.data_inicio} onChange={handleChange} />
                <p id="center-p">Data de Conclusão</p>
                <input id="data_termino" type="month" name="data_termino" value={formData.data_termino} onChange={handleChange} />
              </div>
              <label htmlFor="estado_estudo">Estado</label>
              <select id="estado_estudo" name="estado_estudo" value={formData.estado_estudo} onChange={handleChange}>
                <option value="VAZIO">Selecione</option>
                <option value="CURSANDO">Cursando</option>
                <option value="COMPLETO">Completo</option>
                <option value="INTERROMPIDO">Interrompido</option>
              </select>
              <button type="button" onClick={addFormacao}> {""}
                {editIndex !== null
                  ? " Atualizar Formação" : "Adicionar Formação"}
              </button>
              <ul>
                {(formData.formacoes || []).length > 0 ? (
                  formData.formacoes.map((formacao, index) => {
                    const dataInicio = new Date(formacao.data_inicio);
                    const mesInicio = dataInicio.getMonth() + 1;
                    const anoInicio = dataInicio.getFullYear();
                    const dataTermino = new Date(formacao.data_termino);
                    const mesTermino = dataTermino.getMonth() + 1;
                    const anoTermino = dataTermino.getFullYear();

                    return (
                      <li key={index} style={{ display: "flex", justifyContent: "space-between", alignItems: "center", }}>
                        <div>
                          <strong>{formacao.curso_candidato}</strong> -{" "}
                          {NivelEscolaridade[formacao.nivel_escolaridade_candidato]}{" "}
                          ({mesInicio}/{anoInicio} - {mesTermino}/{anoTermino}) -
                          {EstadoEscolaridade[formacao.estado_estudo]}
                        </div>
                        <div style={{ display: "flex", justifyContent: "flex-end", alignItems: "center", gap: "10px", }}>
                          <button onClick={(e) => editFormacao(index, e)}>
                            Editar
                          </button>
                          <button onClick={() => deleteFormacao(index)}>
                            Excluir
                          </button>
                        </div>
                      </li>
                    );
                  })
                ) : (<li>Nenhuma formação cadastrada.</li>)}
              </ul>
              <br />
              <br />
              <button type="submit" className="submit-forms" id="center-button" onClick={handleButtonClick}>
                {buttonText}
              </button>
            </section>
          </form>
          <br />
          <br />
          <h2>Habilidades</h2>
          <form onSubmit={handleSubmit}>
            <section>
              <label htmlFor="tipo_habilidade">Tipo de Habilidade</label>
              <select id="tipo_habilidade" name="tipo_habilidade" value={formData.tipo_habilidade} onChange={handleChange}>
                <option value=" ">{TipoHabilidade.VAZIO}</option>
                <option value="TECNICA">{TipoHabilidade.TECNICA}</option>
                <option value="COMPORTAMENTAL">
                  {TipoHabilidade.COMPORTAMENTAL}
                </option>
                <option value="CERTIFICACAO">
                  {TipoHabilidade.CERTIFICACAO}
                </option>
              </select>
              <label htmlFor="habilidade">Habilidade</label>
              <input id="habilidade_candidato" type="text" name="habilidade_candidato" placeholder="Exemplo: programação, design, línguas estrangeiras, etc" value={formData.habilidade_candidato} onChange={handleChange} />
              <label htmlFor="nivel_conhecimento">Nível de Conhecimento</label>
              <div id="nivel_conhecimento" style={{ display: "flex", flexDirection: "column", alignItems: "center", }}>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <span style={{ marginRight: "15px", fontWeight: "bold", fontSize: "20px", }}>
                    Básico
                  </span>
                  <div style={{ display: "flex", justifyContent: "center" }}> {""}
                    {[...Array(5)].map((_, index) => {
                      const value = index + 1;
                      return (
                        <label key={index} style={{ position: " relative" }}>
                          <input type="radio" name="nivel_conhecimento" value={value} checked={formData.nivel_conhecimento === value} onChange={handleChange} style={{ display: "none" }} />
                          <span style={{ fontSize: "40px", color: formData.nivel_conhecimento >= value ? "crimson" : "gray", cursor: "pointer", }}
                            onClick={() => setFormData({ ...formData, nivel_conhecimento: value, })} >
                            {value % 1 === 0 ? "★" : "☆"}{" "}
                          </span>
                        </label>
                      );
                    })}
                  </div>
                  <span style={{ marginLeft: "15px", fontWeight: "bold", fontSize: "20px", }}>
                    Avançado
                  </span>
                </div>
              </div>
              <button type="button" onClick={addHabilidade}> {""}
                {editIndex !== null ? " Atualizar Habilidade" : "Adicionar Habilidade"}
              </button>
              <ul>
                {formData.habilidades.map((habilidade, index) => (
                  <li key={index} style={{ display: "flex", justifyContent: "space-between", alignItems: "center", }}>
                    <strong style={{ flex: 2 }}> {""} Habilidade: {habilidade.habilidade_candidato} </strong>
                    <div style={{ flex: 1, textAlign: " center" }}> {""}
                      Tipo: {TipoHabilidade[habilidade.tipo_habilidade]}
                    </div>
                    <div style={{ flex: 1, textAlign: " center" }}> {""}
                      Nível: {renderStars(habilidade.nivel_conhecimento)}
                    </div>
                    <div style={{ flex: 1, display: " flex", justifyContent: "flex-end", alignItems: "center", gap: "10px", }}>
                      <button onClick={(e) => editHabilidade(index, e)} style={{ display: "flex" }} >
                        Editar
                      </button>
                      <button onClick={() => deleteHabilidade(index)} style={{ display: "flex" }} >
                        Excluir
                      </button>
                    </div>
                  </li>
                ))}
              </ul>
              <br />
              <br />
              <button type="submit" className="submit-forms" id="center-button" onClick={handleButtonClick}>
                {buttonText}
              </button>
            </section>
          </form>
          <h2>Experiência</h2>
          <form onSubmit={handleSubmit}>
            <section>
              {/* Campos de entrada */}
              <label htmlFor="nome_empresa">Nome da Empresa</label>
              <input id="nome_empresa" type="text" name="nome_empresa" value={formData.nome_empresa} onChange={handleChangeExperiencia} />
              <label htmlFor="cargo">Cargo</label>
              <input id="cargo" type="text" name="cargo" value={formData.cargo} onChange={handleChangeExperiencia} />
              <label htmlFor="tipo_cargo">Tipo de Cargo</label>
              <select id="tipo_cargo" name="tipo_cargo" value={formData.tipo_cargo} onChange={handleChangeExperiencia}>
                <option value="CLT">CLT</option>
                <option value="PJ">PJ</option>
                <option value="Estagiario">Estagiário</option>
                <option value="Autonomo">Autônomo</option>
                <option value="Temporario">Temporário</option>
              </select>
              <label htmlFor="data_entrada">Data de Entrada</label>
              <input id="data_entrada" type="month" name="data_entrada" value={formData.data_entrada} onChange={handleChangeExperiencia} />
              <label htmlFor="data_saida">Data de Saída</label>
              <input id="data_saida" type="month" name="data_saida" value={formData.data_saida} onChange={handleChangeExperiencia} disabled={formData.emprego_atual} />
              {/* <div className="multiple-input-container">
		          <label htmlFor="emprego_atual" className="animated-checkbox">Emprego Atual</label>
		          <input id="emprego_atual" type="checkbox" name="emprego_atual" checked={formData.emprego_atual} onChange={handleChangeExperiencia}/>
	            </div> */}
              <div className="multiple-input-container">
                <label htmlFor="emprego_atual" className="checkbox-container">
                  Emprego Atual
                  <input id="emprego_atual" type="checkbox" name="emprego_atual" checked={formData.emprego_atual || false} onChange={handleChangeExperiencia} />
                  <span className="custom-checkbox" />
                </label>
              </div>
              <button type="button" onClick={addExperiencia}> {""}
                {editIndexExperiencia !== null ? " Atualizar Experiência" : "Adicionar Experiência"}
              </button>
              <ul>
                {formData.experiencias.map((experiencia, index) => (
                  <li key={index} style={{ display: "flex", justifyContent: "space-between", alignItems: "center", }}>
                    <div>
                      <strong>Empresa:</strong> {experiencia.nome_empresa}
                      <br />
                      <strong>Cargo:</strong> {experiencia.cargo}
                    </div>
                    <div style={{ flex: 1, display: "flex", justifyContent: "flex-end", alignItems: "center", gap: "10px", }}>
                      <button onClick={(e) => editExperiencia(index, e)} style={{ display: "flex" }} >
                        Editar
                      </button>
                      <button onClick={() => deleteExperiencia(index)} style={{ display: "flex" }} >
                        Excluir
                      </button>
                    </div>
                  </li>
                ))}
              </ul>
              <br />
              <br />
              <button type="submit" className="submit-forms" id="center-button" onClick={handleButtonClick}>
                {buttonText}
              </button>
            </section>
          </form>
        </div>
      </div>
    </>
  );
};

export default OnBoarding;

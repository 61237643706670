// Chat.js
import React, { useEffect, useRef } from 'react';

const Chat = ({ descendingOrderMessages }) => {
  const endOfMessagesRef = useRef(null); // Referência para o final das mensagens

  // Função para rolar até o final do chat
  const scrollToBottom = () => {
    endOfMessagesRef.current?.scrollIntoView({ behavior: "smooth", block: "end" });
  };

  useEffect(() => {
    scrollToBottom();  // Chama a rolagem sempre que as mensagens mudam
  }, [descendingOrderMessages]);  // Quando as mensagens mudam

  if (!descendingOrderMessages || descendingOrderMessages.length === 0) {
    return <p>Nenhuma mensagem para exibir.</p>;
  }

  return (
    <div className="chat-display">
      {descendingOrderMessages.map((message, index) => (
        // <div key={index} className={`chat-message-container ${!!message.isSentByUser ? 'sent' : 'received'}`} >
        <div key={index} className={`chat-message-container ${message.isSentByUser === 'sent' ? 'sent' : 'received'}`} >
          <div className="chat-message-header">
            <div className="img-container">
              <div className="match-initial-header">
                {message.img}
              </div>
            </div>
            <p>{message.name}</p>
          </div>
          <div className="chat-message-body">
            <p className="message-text">{message.message}</p>
            <span className="timestamp">
              {new Date(message.timestamp).toLocaleTimeString()}
            </span>
          </div>
        </div>
      ))}
      <div ref={endOfMessagesRef} />
    </div>
  );
};

export default Chat;
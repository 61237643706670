// services/authService.js
import axios from "axios";

const API_URL = process.env.REACT_APP_API_BASE_URL;

export const loginCandidate = async (email, password) => {
    const response = await axios.post(`${API_URL}/candidato/login`, { email, password }, { withCredentials: true });
    return response.data;
};

export const signupCandidate = async (nome, cpf, email, password) => {
    const response = await axios.post(`${API_URL}/candidato/signup`, { nome, cpf, email, password }, { withCredentials: true });
    return response.data;
};

export const loginCompany = async (email, password) => {
    const response = await axios.post(`${API_URL}/empresa/login`, { email, password }, { withCredentials: true });
    return response.data;
};

export const signupCompany = async (nome, cnpj, email, password) => {
    const response = await axios.post(`${API_URL}/empresa/signup`, { nome, cnpj, email, password }, { withCredentials: true });
    return response.data;
};
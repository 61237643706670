import React, { useEffect, useState, useCallback } from "react";
import ChatContainer from "../components/ChatContainer";
import Nav from "../components/Nav";
import { FaCheck, FaXmark } from "react-icons/fa6";
import { useAuth } from "../contexts/AuthContext";
import { fetchJobListings, fetchCandidates, acceptConnection, rejectConnection, fetchConnectionsByType, getInfo } from "../services/dashboardService";
import { useJob } from "../contexts/JobContext";


function Dashboard() {
  const { user } = useAuth(); // Obter o usuário do AuthContext
  const { userId, userType, authToken } = user;
  const { selectedJobId } = useJob();

  const [selectedItem, setSelectedItem] = useState(null);
  const [activeTab, setActiveTab] = useState("informacoes");
  const [isModalVisible, setIsModalVisible] = useState(false);

  const [connections, setConnections] = useState([]);

  const [filteredCandidates, setFilteredCandidates] = useState([]);
  const [filteredJobListings, setFilteredJobListings] = useState([]);

  const [candidates, setCandidates] = useState([]);
  const [jobListings, setJobListings] = useState([]);

  const [candidatosCompativeis, setCandidatosCompativeis] = useState([]);
  const [vagasCompativeis, setVagasCompativeis] = useState([]);

  const fetchData = useCallback(async () => {
    try {
      if (userType === "candidato") {
        // 1. Buscar as vagas e armazenar na lista original
        const jobListingsData = await fetchJobListings(authToken);
        setJobListings(jobListingsData); // Lista original sem modificações
  
        const candidatoInfo = await getInfo(userType, userId, authToken);
  
        if (!candidatoInfo || !Array.isArray(candidatoInfo.habilidades) || !Array.isArray(candidatoInfo.formacoes)) {
          throw new Error("Dados do candidato estão incompletos ou inválidos.");
        }
  
        // 2. Calcular a compatibilidade sem modificar a lista original
        const calculateJobMatch = (job) => {
          let totalWeight = 0;
          let matchScore = 0;
  
          // Formações requeridas
          job.formacoes_requeridas.forEach((formacaoRequerida) => {
            totalWeight++;
            const hasMatchingFormacao = candidatoInfo.formacoes.some(
              (formacaoCandidato) =>
                formacaoCandidato.curso_candidato.toLowerCase() ===
                formacaoRequerida.curso_requerido.toLowerCase()
            );
            if (hasMatchingFormacao) {
              matchScore++;
            }
          });
  
          // Habilidades requeridas
          job.habilidades_requeridas.forEach((habilidadeRequerida) => {
            totalWeight++;
            const matchingHabilidade = candidatoInfo.habilidades.find((habilidadeCandidato) => {
              if (
                habilidadeCandidato?.habilidade_candidato &&
                habilidadeRequerida?.habilidade
              ) {
                return (
                  habilidadeCandidato.habilidade_candidato.toLowerCase() ===
                  habilidadeRequerida.habilidade.toLowerCase()
                );
              }
              return false;
            });
  
            if (matchingHabilidade) {
              const proficienciaRequerida = habilidadeRequerida.proficiencia || 0;
              const nivelConhecimento = matchingHabilidade.nivel_conhecimento || 0;
              matchScore += Math.min(nivelConhecimento, proficienciaRequerida) / proficienciaRequerida;
            }
          });
  
          // Cálculo final
          const compatibility = totalWeight > 0 ? (matchScore / totalWeight) * 100 : 0;
          return { ...job, compatibilidade: compatibility.toFixed(2) };
        };
  
        // 3. Atualizar vagas compatíveis separadamente
        const updatedJobListings = jobListingsData.map(calculateJobMatch);

        const vagasOrdenadas = [...updatedJobListings].sort((a, b) => {
          return parseFloat(b.compatibilidade) - parseFloat(a.compatibilidade);
        });
        
        setVagasCompativeis(vagasOrdenadas);
      } else if (userType === "empresa") {
        // 1. Buscar candidatos e armazenar na lista original
        const candidatesData = await fetchCandidates(authToken);
        setCandidates(candidatesData); // Lista original sem modificações
  
        if (!selectedJobId) {
          setCandidatosCompativeis([]);
          return; // Interrompe a execução para evitar o restante do bloco
        }
        const vagaInfo = await getInfo(userType, selectedJobId, authToken);
  
        if (!vagaInfo || !Array.isArray(vagaInfo.habilidades_requeridas) || !Array.isArray(vagaInfo.formacoes_requeridas)) {
          throw new Error("Dados da vaga estão incompletos ou inválidos.");
        }
  
        // 2. Calcular a compatibilidade sem modificar a lista original
        const calculateCandidateMatch = (candidate) => {
          let totalWeight = 0;
          let matchScore = 0;
  
          // Formações requeridas
          vagaInfo.formacoes_requeridas.forEach((formacaoRequerida) => {
            totalWeight++;
            const hasMatchingFormacao = candidate.formacoes.some(
              (formacaoCandidato) =>
                formacaoCandidato.curso_candidato.toLowerCase() ===
                formacaoRequerida.curso_requerido.toLowerCase()
            );
            if (hasMatchingFormacao) {
              matchScore++;
            }
          });
  
          // Habilidades requeridas
          vagaInfo.habilidades_requeridas.forEach((habilidadeRequerida) => {
            totalWeight++;
            const matchingHabilidade = candidate.habilidades.find(
              (habilidadeCandidato) =>
                habilidadeCandidato.habilidade_candidato.toLowerCase() ===
                habilidadeRequerida.habilidade.toLowerCase()
            );
  
            if (matchingHabilidade) {
              const proficienciaRequerida = habilidadeRequerida.proficiencia;
              const nivelConhecimento = matchingHabilidade.nivel_conhecimento;
              matchScore += Math.min(nivelConhecimento, proficienciaRequerida) / proficienciaRequerida;
            }
          });
  
          // Cálculo final
          const compatibility = totalWeight > 0 ? (matchScore / totalWeight) * 100 : 0;
          return { ...candidate, compatibilidade: compatibility.toFixed(2) };
        };
  
        // 3. Atualizar candidatos compatíveis separadamente
        const updatedCandidates = candidatesData.map(calculateCandidateMatch);
        
        const candidatosOrdenados = [...updatedCandidates].sort((a, b) => {
          return parseFloat(b.compatibilidade) - parseFloat(a.compatibilidade);
        });

        setCandidatosCompativeis(candidatosOrdenados);
      }
    } catch (error) {
      console.error("Erro ao buscar dados:", error);
      alert("Erro ao buscar dados. Tente novamente mais tarde.");
    }
  }, [authToken, userType, selectedJobId, userId]);
  


  // const fetchData = useCallback(async () => {
  //   try {
  //     if (userType === "candidato") {
        // const jobListingsData = await fetchJobListings(authToken);
        // setJobListings(jobListingsData);
  //     } else if (userType === "empresa") {
        // const candidatesData = await fetchCandidates(authToken);
        // setCandidates(candidatesData);
  //     }
  //   } catch (error) {
  //     console.error("Erro ao buscar dados:", error);
  //     alert("Erro ao buscar dados. Tente novamente mais tarde.");
  //   }
  // }, [authToken, userType]);





  const filterResults = (query) => {
    const lowerCaseQuery = query.toLowerCase();
    if (userType === "empresa") {
      setFilteredCandidates(
        candidatosCompativeis.filter((candidate) =>
          candidate.formacoes.some((formacao) =>
            (formacao.curso_candidato || "").toLowerCase().includes(lowerCaseQuery)
          ) ||
          candidate.habilidades.some((habilidade) =>
            (habilidade.habilidade_candidato || "").toLowerCase().includes(lowerCaseQuery)
          ) ||
          (candidate.cidade || "").toLowerCase().includes(lowerCaseQuery)
        )
      );
    } else if (userType === "candidato") {
      setFilteredJobListings(
        vagasCompativeis.filter((job) =>
          (job.titulo || "").toLowerCase().includes(lowerCaseQuery) || // Filtrar pelo título da vaga
          (job.empresa_nome || "").toLowerCase().includes(lowerCaseQuery) || // Filtrar pelo nome da empresa
          job.formacoes_requeridas.some((formacao) =>
            (formacao.curso_requerido || "").toLowerCase().includes(lowerCaseQuery)
          ) ||
          job.habilidades_requeridas.some((habilidade) =>
            (habilidade.habilidade || "").toLowerCase().includes(lowerCaseQuery)
          )
        )
      );
    }
  };


  const fetchMatch = useCallback(async () => {
    try {
      const connectionsData = await fetchConnectionsByType(authToken, userType, userId);
      setConnections(connectionsData);
    } catch (error) {
      console.error("Erro ao buscar conexões:", error);
    }
  }, [authToken, userType, userId]);

  useEffect(() => {
    fetchData();
    fetchMatch();
  }, [fetchData, fetchMatch]);

  const handleItemClick = (item) => {
    setSelectedItem(item);
    setIsModalVisible(true);
  };

  const closeModal = () => {
    setIsModalVisible(false);
    setTimeout(() => setSelectedItem(null), 500);
  };


  const handleAccept = async (item) => {
    if (!item) {
      alert("Nenhum item selecionado.");
      return;
    }

    try {
      const payload = userType === "empresa"
        ? {
          userType,            // Tipo de usuário (empresa)
          userId,              // ID da empresa logada
          id_vaga: selectedJobId,  // ID da vaga selecionada
          id_candidato: item._id,  // ID do candidato selecionado
          aceite_empresa: true,    // Aceite pela empresa
        }
        : {
          userType,            // Tipo de usuário (candidato)
          userId,              // ID do candidato logado
          id_vaga: item._id,   // ID da vaga selecionada
          id_empresa: item.empresaId, // ID da empresa associada à vaga
          aceite_candidato: true,      // Aceite pelo candidato
        };

      const response = await acceptConnection(authToken, payload);

      if (response.status === 200 || response.status === 201) {
        alert(response.message || "Ação concluída com sucesso");
        window.location.reload()
        fetchMatch(); // Atualiza o estado no frontend
        closeModal();
      } else {
        alert("Erro ao processar a solicitação.");
      }
    } catch (error) {
      console.error("Erro ao aceitar item:", error);
      alert("Ocorreu um erro ao aceitar o item.");
    }
  };

  const renderStars = (nivel) => {
    return [...Array(5)].map((_, index) => {
      const value = index + 1;
      return (
        <span
          key={index}
          style={{
            color: nivel >= value ? "crimson" : "gray",
            cursor: "pointer",
          }}
        >
          {value % 1 === 0 ? "★" : "☆"}
        </span>
      );
    });
  };

  const handleReject = async (item) => {
    if (!item) {
      alert("Nenhum item selecionado.");
      return;
    }

    try {
      const payload = userType === "empresa"
        ? {
          userType,            // Tipo de usuário (empresa)
          userId,              // ID da empresa logada
          id_vaga: selectedJobId,  // ID da vaga selecionada
          id_candidato: item._id,  // ID do candidato selecionado
          recusa_empresa: true,    // Aceite pela empresa
        }
        : {
          userType,            // Tipo de usuário (candidato)
          userId,              // ID do candidato logado
          id_vaga: item._id,   // ID da vaga selecionada
          id_empresa: item.empresaId, // ID da empresa associada à vaga
          recusa_candidato: true,      // Aceite pelo candidato
        };

      const response = await rejectConnection(authToken, payload);

      if (response.status === 200 || response.status === 201) {
        alert(response.message || "Ação concluída com sucesso");
        fetchMatch(); // Atualiza o estado no frontend
        closeModal();
      } else {
        alert("Erro ao processar a solicitação.");
      }
    } catch (error) {
      console.error("Erro ao aceitar item:", error);
      alert("Ocorreu um erro ao aceitar o item.");
    }
  };


  return (
    <div>
      {/* <Nav setShowModal={() => { }} showModal={false} /> */}
      <Nav onSearch={filterResults} setShowModal={() => { }} showModal={false} />
      <div className="dashboard">
        <ChatContainer user={user} />
        <div className="card-container">
          <h2 className="h2-title-dashboard">
            {userType === "candidato" ? "Vagas" : selectedJobId ? <strong>Candidatos</strong> : <strong>Selecione uma vaga para encontrar candidatos</strong>}
          </h2>

          {(userType === "candidato" || selectedJobId) && (
            <div className="cards">
              {(userType === "candidato" ? (filteredJobListings.length === 0 ? vagasCompativeis : filteredJobListings) : (filteredCandidates.length === 0 ? candidatosCompativeis : filteredCandidates)).map((item) => {
                const connection = connections.find(conn => userType === "empresa" ? conn.id_candidato === item._id && conn.id_vaga === selectedJobId : conn.id_vaga === item._id) || {};
                const isAcceptDisabled = userType === "empresa" ? !selectedJobId || connection.aceite_empresa : connection.aceite_candidato;
                const isRejectDisabled = userType === "empresa" ? !selectedJobId || connection.recusa_empresa : connection.recusa_candidato;

                return (
                  <div
                    key={item._id}
                    onDoubleClick={() => handleItemClick(item)}
                    className="card"
                  >
                    {/* <h3>{userType === "candidato" ? `${item.titulo}${item.empresa_nome ? item.empresa_nome : ""}` : item.nome}</h3> */}
                    <h3> {userType === "candidato" ? `${item.titulo}${item.empresa_nome ? ` - ${item.empresa_nome}` : ""}` :

                      <strong>{item.nome}, {item.data_nascimento ? (() => {
                        const nascimento = new Date(item.data_nascimento);
                        const hoje = new Date();
                        let idade = hoje.getFullYear() - nascimento.getFullYear();
                        const mes = hoje.getMonth() - nascimento.getMonth();

                        // Ajustar caso ainda não tenha feito aniversário este ano
                        if (mes < 0 || (mes === 0 && hoje.getDate() < nascimento.getDate())) {
                          idade--;
                        }

                        return idade;
                      })() : "N/A"}
                      </strong>
                    }
                    </h3>

                    {userType === "candidato" ? (<p>Compatibilidade com a vaga: {item.compatibilidade}%</p>) : (<p>Compatibilidade com o candidato: {item.compatibilidade}%</p>)}

                    {userType === "candidato" && item.salario > 0 ? (<p>Salário: R$ {item.salario}</p>) : ("")}

                    {/* <p>
                      {userType === "candidato" ? `Modalidade: ${item.modalidade === "outro" ? item.outra_modalidade : item.modalidade === "presencial" || item.modalidade === "hibrido" ? `${item.modalidade} - ${item.cidade}` : item.modalidade}${item.tipo_contrato ? ` - Contrato: ${item.tipo_contrato}` : ""}`
                        : item.habilidades && item.habilidades.length > 0 ? `Habilidades: ${item.habilidades.map(h => h.habilidade_candidato).join(', ')}`
                          : "Habilidade não informada"}
                    </p> */}

                    <p>
                      {userType === "candidato" ? item.descricao : item.formacoes && item.formacoes.length > 0 ? `${item.formacoes[0].curso_candidato} - ${item.formacoes[0].instituicao_candidato}` : "Formação não informada"}
                    </p>

                    <p>
                      {userType === "candidato"
                        ? ""
                        : item.experiencias && item.experiencias.length > 0
                          ? `Experiencia: ${item.experiencias[0].cargo} - ${item.experiencias[0].nome_empresa}`
                          : "Experiencia não informada"
                      }
                    </p>

                    {userType === "empresa" && item.habilidades && item.habilidades.length > 0 && (
                      <div>
                        <strong>Habilidades:</strong>
                        <ul className="ul-card">
                          {item.habilidades.map((habilidade, idx) => (
                            <li key={idx} style={{ marginBottom: '0px' }}>
                              <span>{habilidade.habilidade_candidato}: </span>
                              <span>
                                {[1, 2, 3, 4, 5].map((level) => (
                                  <span key={level} style={{ color: level <= habilidade.nivel_conhecimento ? 'crimson' : 'gray', fontSize: '18px', }} >
                                    ★
                                  </span>
                                ))}
                              </span>
                            </li>
                          ))}
                        </ul>
                      </div>
                    )}

                    <div className="requirements">
                      {userType === "candidato" && item.habilidades_requeridas && item.habilidades_requeridas.length > 0 && (
                        <div>
                          <strong>Requisitos:</strong>
                          <ul className="ul-card">
                            {item.habilidades_requeridas.map((requirement, idx) => (
                              <li key={idx} style={{ marginBottom: '0px' }}>
                                <span>{requirement.habilidade}: </span>
                                <span>
                                  {[1, 2, 3, 4, 5].map((level) => (
                                    <span key={level} style={{ color: level <= requirement.proficiencia ? 'crimson' : 'gray', fontSize: '18px', }} >
                                      ★
                                    </span>
                                  ))}
                                </span>
                              </li>
                            ))}
                          </ul>
                          <span>
                            {item.formacoes_requeridas && item.formacoes_requeridas.length > 0 ? `${item.formacoes_requeridas[0].nivel_escolaridade_requerido} - ${item.formacoes_requeridas[0].curso_requerido}` : ""}
                          </span>

                        </div>
                      )}
                    </div>

                    <div className="card-buttons">
                      <button
                        className={`accept-button ${isAcceptDisabled ? "disabled-button" : ""}`}
                        onClick={() => handleAccept(item)}
                        disabled={isAcceptDisabled}
                      >
                        <FaCheck /> Aceitar
                      </button>
                      <button
                        className={`reject-button ${isRejectDisabled ? "disabled-button" : ""}`}
                        onClick={() => handleReject(item)}
                        disabled={isRejectDisabled}
                      >
                        <FaXmark /> Recusar
                      </button>
                    </div>
                  </div>
                );
              })}
            </div>
          )}


        </div>
      </div>

      {selectedItem && (
        <div className={`modal ${isModalVisible ? "show" : ""}`}>
          <div className="modal-content">
            <span className="close" onClick={closeModal}>
              &times;
            </span>
            <h2>{userType === "candidato" ? selectedItem.titulo : selectedItem.nome}</h2>
            <div className="tabs">
              <button className="modal-button" onClick={() => setActiveTab("informacoes")}>Informações</button>
              {userType === "empresa" ? (
                <>
                  <button className="modal-button" onClick={() => setActiveTab("formacoes")}>Formações</button>
                  <button className="modal-button" onClick={() => setActiveTab("habilidades")}>Habilidades</button>
                  <button className="modal-button" onClick={() => setActiveTab("experiencias")}>Experiências</button>
                </>
              ) : (
                <>
                  <button className="modal-button" onClick={() => setActiveTab("descricao")}>Descrição</button>
                  <button className="modal-button" onClick={() => setActiveTab("requisitos")}>Requisitos</button>
                  <button className="modal-button" onClick={() => setActiveTab("beneficios")}>Benefícios</button>
                </>
              )}
            </div>
            <div className="scroll-content">
              {activeTab === "informacoes" && (
                <div>
                  {userType === "empresa" ? (
                    <>
                      <p><strong>Email:</strong> {selectedItem.email || "N/A"}</p>
                      {/* <p><strong>Data de Nascimento:</strong> {selectedItem.data_nascimento ? new Date(selectedItem.data_nascimento + 'T00:00:00').toLocaleDateString('pt-BR') : "N/A"}</p> */}
                      <p><strong>Idade:</strong> {selectedItem.data_nascimento ? (() => {
                        const nascimento = new Date(selectedItem.data_nascimento);
                        const hoje = new Date();
                        let idade = hoje.getFullYear() - nascimento.getFullYear();
                        const mes = hoje.getMonth() - nascimento.getMonth();

                        // Ajustar caso ainda não tenha feito aniversário este ano
                        if (mes < 0 || (mes === 0 && hoje.getDate() < nascimento.getDate())) {
                          idade--;
                        }

                        return idade;
                      })() : "N/A"}</p>
                      <p><strong>Cidade:</strong> {selectedItem.cidade || "N/A"}</p>
                      <p><strong>LinkedIn:</strong> <a href={selectedItem.linkedin || "#"}>{selectedItem.linkedin || "N/A"}</a></p>
                      <p>{selectedItem.sobre}</p>
                    </>
                  ) : (
                    <>
                      <p><strong>Salário: </strong>R$ {selectedItem.salario || "N/A"}</p>
                      <p><strong>Tipo de Contrato:</strong> {selectedItem.tipo_contrato || "N/A"}</p>
                      <p><strong>Modalidade:</strong> {selectedItem.modalidade || "N/A"}</p>
                      <p><strong>Endereço:</strong> {selectedItem.cidade ? `${selectedItem.cidade}, ` : ""}
                        {selectedItem.uf ? `${selectedItem.uf}, ` : ""} {selectedItem.bairro ? `${selectedItem.bairro}, ` : ""} {selectedItem.logradouro ? `${selectedItem.logradouro}, ` : ""}
                        {selectedItem.numero ? `${selectedItem.numero}, ` : ""} {selectedItem.complemento ? `${selectedItem.complemento}, ` : ""} {selectedItem.cep ? `${selectedItem.cep}. ` : ""}
                      </p>
                      {selectedItem.politica_diversidade ? (<p> <strong>Politica de Diversidade:</strong> {selectedItem.politica_diversidade}</p>) : ("")}


                    </>
                  )}
                </div>
              )}
              {activeTab === "descricao" && userType === "candidato" && selectedItem.descricao && (
                <div>
                  <h3>Descrição</h3>
                  <p>{selectedItem.descricao}</p>
                </div>
              )}
              {activeTab === "requisitos" && userType === "candidato" && selectedItem.habilidades_requeridas && (


                <div className="requirements">
                  {userType === "candidato" && selectedItem.habilidades_requeridas && selectedItem.habilidades_requeridas.length > 0 && (
                    <div>
                      <br />
                      {/* <strong>Requisitos:</strong> */}
                      {selectedItem.documentos_necessarios ? (<p> <strong>Documentos Necessários:</strong> <br></br>{selectedItem.documentos_necessarios}</p>) : ("")}
                      <p style={{ marginLeft: "15%", textAlign: "left", marginBottom: "1px" }}><strong>Habilidades Necessárias:</strong></p>
                      <ul style={{ marginLeft: "15%", textAlign: "left" }}>
                        {selectedItem.habilidades_requeridas.map((requirement, idx) => (
                          <li key={idx} style={{ marginBottom: '0px', padding: "5px" }}>
                            <span>{requirement.habilidade}: </span>
                            <span>
                              {[1, 2, 3, 4, 5].map((level) => (
                                <span key={level} style={{ color: level <= requirement.proficiencia ? 'crimson' : 'gray', fontSize: '18px' }} >
                                  ★
                                </span>
                              ))}
                            </span>
                          </li>
                        ))}
                      </ul>
                      <span>
                        {selectedItem.formacoes_requeridas && selectedItem.formacoes_requeridas.length > 0 ? <p>{`${selectedItem.formacoes_requeridas[0].nivel_escolaridade_requerido} - ${selectedItem.formacoes_requeridas[0].curso_requerido}`}</p> : ""}
                      </span>

                    </div>
                  )}
                </div>



              )}
              {activeTab === "beneficios" && userType === "candidato" && selectedItem.beneficios && (
                <div>
                  <h3>Benefícios</h3>
                  <p>{selectedItem.beneficios}</p>
                </div>
              )}
              {activeTab === "formacoes" && userType === "empresa" && selectedItem.formacoes && (
                <div>

                  {selectedItem.formacoes.length > 0 ? (
                    selectedItem.formacoes.map((formacao, index) => (
                      <div key={index}>
                        <p><strong>Curso:</strong> {formacao.curso_candidato}</p>
                        <p><strong>Instituição:</strong> {formacao.instituicao_candidato}</p>
                        <p><strong>Nível Escolaridade:</strong> {formacao.nivel_escolaridade_candidato}</p>
                        <p><strong>Início:</strong> {formacao.data_inicio ? new Date(formacao.data_inicio + 'T00:00:00').toLocaleString('pt-BR', { year: 'numeric', month: 'short' }).replace('.', '') : "N/A"}</p>
                        <p><strong>Termino:</strong> {formacao.data_termino ? new Date(formacao.data_termino + 'T00:00:00').toLocaleString('pt-BR', { year: 'numeric', month: 'short' }).replace('.', '') : "N/A"}</p>
                        <p><strong>Status:</strong> {formacao.estado_estudo}</p>
                        <hr />
                      </div>
                    ))
                  ) : (
                    "Sem formações"
                  )}

                  <p>{selectedItem.descricao}</p>
                </div>
              )}

              {activeTab === "habilidades" && userType === "empresa" && selectedItem.habilidades && (

                <ul>
                  {selectedItem.habilidades.map((habilidade, index) => (
                    <li key={index} style={{ display: "flex", justifyContent: "space-between", alignItems: "center", }}>
                      <strong style={{ flex: 2 }}> {""} Habilidade: {habilidade.habilidade_candidato} </strong>
                      <div style={{ flex: 1, textAlign: " center" }}> {""}
                        Tipo: {habilidade.tipo_habilidade}
                      </div>
                      <div style={{ flex: 1, textAlign: " center" }}> {""}
                        Nível: {renderStars(habilidade.nivel_conhecimento)}
                      </div>
                      <div style={{ flex: 1, display: " flex", justifyContent: "flex-end", alignItems: "center", gap: "10px", }}>
                      </div>
                    </li>
                  ))}
                </ul>
              )}

              {activeTab === "experiencias" && userType === "empresa" && selectedItem.experiencias && (
                <div>
                  {selectedItem.experiencias.length > 0 ? (
                    selectedItem.experiencias.map((experiencia, index) => (
                      <div key={index}>
                        <p><strong>Empresa:</strong> {experiencia.nome_empresa}</p>
                        <p><strong>Cargo:</strong> {experiencia.cargo}</p>
                        {/* <p><strong>Periodo:</strong> {`${experiencia.data_entrada ? new Date(experiencia.data_entrada + 'T00:00:00').toLocaleDateString('pt-BR') : "N/A"} - ${experiencia.data_saida ? new Date(experiencia.data_saida + 'T00:00:00').toLocaleDateString('pt-BR') : "N/A"}`}</p> */}
                        {/* <p><strong>Periodo:</strong> {`${experiencia.data_entrada ? new Date(experiencia.data_entrada + 'T00:00:00').toLocaleString('pt-BR', { year: 'numeric', month: '2-digit' }) : "N/A"} - ${experiencia.data_saida ? new Date(experiencia.data_saida + 'T00:00:00').toLocaleString('pt-BR', { year: 'numeric', month: '2-digit' }) : "N/A"}`}</p> */}
                        <p><strong>Periodo:</strong> {`${experiencia.data_entrada ? new Date(experiencia.data_entrada + 'T00:00:00').toLocaleString('pt-BR', { year: 'numeric', month: 'short' }).replace('.', '') : "N/A"} - ${experiencia.emprego_atual ? "Atual" : (experiencia.data_saida ? new Date(experiencia.data_saida + 'T00:00:00').toLocaleString('pt-BR', { year: 'numeric', month: 'short' }).replace('.', '') : "N/A")}`}</p>
                        <hr />
                      </div>
                    ))
                  ) : (
                    "Sem formações"
                  )}
                  <p>{selectedItem.descricao}</p>
                </div>
              )}
            </div>

            <div className="card-buttons" id="button-container">
              <button className="accept-button" onClick={() => handleAccept(selectedItem)}>
                <FaCheck /> Aceitar
              </button>
              <button className="reject-button" onClick={() => handleReject(selectedItem)}>
                <FaXmark /> Recusar
              </button>
            </div>

          </div>
        </div>
      )}
    </div>
  );
}

export default Dashboard;

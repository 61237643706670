// ChatHeader.js
const ChatHeader = ({ user }) => {
    // Obtenção das iniciais do primeiro e último nome
    const nameParts = user.userName.split(" ");
    const firstInitial = nameParts[0].charAt(0); // Primeira letra do primeiro nome
    const lastInitial = nameParts[nameParts.length - 1].charAt(0); // Primeira letra do último nome

    return (
        <div className="chat-container-header">
            <div className="profile">
                <div className="img-container">
                    <div className="match-initial-header">
                        {firstInitial}{lastInitial} {/* Exibe as iniciais */}
                    </div>
                </div>
                <h3>{user.userName}</h3>
            </div>
        </div>
    )
}

export default ChatHeader;
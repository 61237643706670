// components/AuthModal.js
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../contexts/AuthContext"; // Importando o AuthContext
import { signupCandidate, loginCandidate } from "../services/authService";

const AuthModal = ({ setShowModal, isSignUp }) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [nome, setNome] = useState("");
  const [cpf, setCpf] = useState("");
  const [error, setError] = useState("");

  const { setAuthData } = useAuth(); // Pegando a função setAuthData do AuthContext
  const navigate = useNavigate();

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Validação de senhas
    if (isSignUp && password !== confirmPassword) {
      setError("As senhas precisam corresponder!");
      return;
    }

    try {
      // Chama a função apropriada do service
      const data = isSignUp
        ? await signupCandidate(nome, cpf, email, password)
        : await loginCandidate(email, password);

      // Decodificar o token JWT retornado
      const token = data.token;

      // Usar a função setAuthData para configurar cookies e estado de autenticação
      setAuthData(token);

      // Navegar para a rota correspondente
      const success = data && token;
      if (success) {
        navigate(isSignUp ? "/onBoarding" : "/dashboard");
      }
    } catch (err) {
      console.error("Erro durante login ou cadastro:", err);
      setError(
        err.response?.data?.message || "Algo deu errado. Verifique suas credenciais."
      );
    }
  };

  return (
    <div className="auth-modal-cand">
      <div className="close-icon" onClick={handleCloseModal}>
        ⓧ
      </div>

      <h2 className="h2-auth-modal">{isSignUp ? "CONTA PERFIL CANDIDATO" : "LOGIN CANDIDATO"}</h2>
      <p>
        Ao clicar em Login, você concorda com nossos termos. Saiba como processamos os seus
        dados na nossa Política de Privacidade e Política de Cookies.
      </p>
      <form onSubmit={handleSubmit}>
        {isSignUp && (
          <>
            <input
              type="text"
              id="nome"
              name="nome"
              placeholder="Nome"
              required
              value={nome}
              onChange={(e) => setNome(e.target.value)}
            />
            <input
              type="text"
              id="cpf"
              name="cpf"
              placeholder="CPF"
              required
              value={cpf}
              onChange={(e) => setCpf(e.target.value)}
            />
          </>
        )}
        <input
          type="email"
          id="email"
          name="email"
          placeholder="E-mail"
          required
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <input
          type="password"
          id="password"
          name="password"
          placeholder="Senha"
          required
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
        {isSignUp && (
          <input
            type="password"
            id="confirm-password"
            name="confirm-password"
            placeholder="Confirme a senha"
            required
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
          />
        )}
        <input
          className="secondary-button"
          type="submit"
          value={isSignUp ? "Cadastrar" : "Login"}
        />
        {error && <p className="error-message">{error}</p>}
      </form>
      <hr />
    </div>
  );
};

export default AuthModal;

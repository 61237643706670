import Nav from '../components/Nav';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import { fetchCompanyData, updateCompanyData } from '../services/companyService';

const OnBoardingEmpresa = () => {
    const { user } = useAuth(); // Obtendo os dados do usuário do AuthContext
    const navigate = useNavigate();

    // Verificação para garantir que o usuário seja uma empresa
    useEffect(() => {
        if (!user || user.userType !== 'empresa') {
            // Redireciona para o login ou página apropriada caso não seja uma empresa
            navigate('/login');
        }
    }, [user, navigate]);

    const [formData, setFormData] = useState({
        nome: user.userName,
        email: "",
        cnpj: "",
        telefone: "",
        sobre: "",
        cep: "",
        logradouro: "",
        numero: "",
        complemento: "",
        bairro: "",
        cidade: "",
        uf: "",
        pais: ""
    });

    // Função para carregar os dados da empresa
    useEffect(() => {
        const loadCompanyData = async () => {
            if (user.userId && user.authToken) {
                try {
                    const data = await fetchCompanyData(user.userId, user.authToken); // Chamando a função para buscar dados
                    setFormData({
                        nome: data.nome || "",
                        email: data.email || "",
                        cnpj: data.cnpj || "",
                        telefone: data.telefone || "",
                        sobre: data.sobre || "",
                        cep: data.cep || "",
                        logradouro: data.logradouro || "",
                        numero: data.numero || "",
                        complemento: data.complemento || "",
                        bairro: data.bairro || "",
                        cidade: data.cidade || "",
                        uf: data.uf || "",
                        pais: data.pais || ""
                    });
                } catch (error) {
                    console.error('Erro ao carregar os dados da empresa:', error);
                }
            }
        };

        loadCompanyData();
    }, [user.userId, user.authToken]);

    // Função de envio do formulário
    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            // Passando o authToken para a função updateCompanyData
            const response = await updateCompanyData(formData, user.authToken); // Atualizando os dados da empresa
            if (response.status === 200) navigate('/dashboard'); // Redirecionando após sucesso
        } catch (err) {
            console.error('Erro ao atualizar os dados da empresa:', err);
        }
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevState => ({ ...prevState, [name]: value }));
    };

    const buscarEnderecoPorCep = async (cep) => {
        try {
          const response = await fetch(`https://viacep.com.br/ws/${cep}/json/`);
          const data = await response.json();
    
          if (data.erro) {
            alert("CEP não encontrado");
            return;
          }
    
          // Preenche os campos do formulário com os dados do retorno
          setFormData({
            ...formData,
            cep: data.cep,
            logradouro: data.logradouro,
            complemento: data.complemento,
            bairro: data.bairro,
            cidade: data.localidade,
            estado: data.estado,
            uf: data.uf,
            pais: "Brasil",
          });
        } catch (error) {
          console.error("Erro ao buscar o CEP:", error);
        }
      };
    
      // Exemplo de uso: Chamar a função ao digitar o CEP
      const handleCepChange = (e) => {
        const cep = e.target.value;
        setFormData({ ...formData, cep: cep });
        if (cep.length === 8) buscarEnderecoPorCep(cep);
      };

    return (
        <>
            <Nav setShowModal={() => { }} showModal={false} />
            <div className="onboarding">
                <h2>CRIE SUA CONTA</h2>
                <form onSubmit={handleSubmit}>
                    <section>
                        <label htmlFor="nome">Nome da Empresa</label>
                        <input id="nome" type='text' name="nome" placeholder="Nome da Empresa" required={true} value={formData.nome} onChange={handleChange} />

                        <label htmlFor="email">E-mail</label>
                        <input id="email" type='text' name="email" placeholder="E-mail" required={true} value={formData.email} onChange={handleChange} />

                        <label htmlFor="cnpj">CNPJ</label>
                        <input id="cnpj" type='text' name="cnpj" placeholder="00.000.000/0000-00" required={true} value={formData.cnpj} onChange={handleChange} />

                        <label htmlFor="telefone">Telefone</label>
                        <input id="telefone" type='text' name="telefone" placeholder="Telefone" required={true} value={formData.telefone} onChange={handleChange} />

                        <label htmlFor="sobre">Sobre a Empresa</label>
                        <textarea id="sobre" type="text" name="sobre" required={true} placeholder="Sobre..." value={formData.sobre} onChange={handleChange} />

                        <label htmlFor="endereco">Endereço</label>
                        <div className="multiple-input-container">
                            <input id="cep" type="text" name="cep" placeholder="CEP" value={formData.cep} onChange={handleCepChange} />
                            <input id="logradouro" type="text" name="logradouro" placeholder="Logradouro" value={formData.logradouro} onChange={handleChange} />
                            <input className="div-number" id="numero" type="number" name="numero" placeholder="Nº" required={true} value={formData.numero} onChange={handleChange} />
                            <input id="complemento" type="text" name="complemento" placeholder="Complemento" value={formData.complemento} onChange={handleChange} />
                        </div>
                        <div className="multiple-input-container">
                            <input id="bairro" type="text" name="bairro" placeholder="Bairro" value={formData.bairro} onChange={handleChange} />
                            <input id="cidade" type="text" name="cidade" placeholder="Cidade" required={true} value={formData.cidade} onChange={handleChange} />
                            <input id="uf" type="text" name="uf" placeholder="UF" value={formData.uf} onChange={handleChange} />
                            <input id="pais" type="text" name="pais" placeholder="País" required={true} value={formData.pais} onChange={handleChange} />
                        </div>

                        <button className='submit-forms' id='center-button' type="submit">SALVAR</button>
                    </section>
                </form>
            </div>
        </>
    );
};

export default OnBoardingEmpresa;

// components/AuthModalEmpresa.js
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../contexts/AuthContext"; // Importando o contexto
import { signupCompany, loginCompany } from "../services/authService";

const AuthModalEmpresa = ({ setShowModal2, isSignUp2 }) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [nome, setNome] = useState("");
  const [cnpj, setCnpj] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [error, setError] = useState("");

  const { setAuthData } = useAuth(); // Pegando a função setAuthData
  const navigate = useNavigate();

  const handleCloseModal = () => {
    setShowModal2(false);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Validação de senhas
    if (isSignUp2 && password !== confirmPassword) {
      setError("As senhas precisam corresponder!");
      return;
    }

    try {
      // Chama o método apropriado do authService
      const data = isSignUp2
        ? await signupCompany(nome, cnpj, email, password)
        : await loginCompany(email, password);

      const token = data.token;

      // Definir os cookies e o estado de autenticação
      setAuthData(token);

      // Navegar para a rota correspondente
      const success = data && token;
      if (success) {
        navigate(isSignUp2 ? "/onBoardingEmpresa" : "/dashboard");
      }
    } catch (err) {
      console.error("Erro durante login ou cadastro:", err);
      setError(
        err.response?.data?.message || "Algo deu errado. Verifique suas credenciais."
      );
    }
  };

  return (
    <div className="auth-modal-emp">
      <div className="close-icon" onClick={handleCloseModal}> ⓧ </div>
      <h2 className="h2-auth-modal"> {isSignUp2 ? "CONTA PERFIL EMPRESA" : "LOGIN EMPRESA"} </h2>
      <p>Ao clicar em Login, você concorda com nossos termos. Saiba como processamos os seus dados na nossa Política de Privacidade e Política de Cookies.</p>
      <form onSubmit={handleSubmit}> {isSignUp2 && (
        <>
          <input type="text" id="nome" name="nome" placeholder="Nome da Empresa" required value={nome} onChange={(e) => setNome(e.target.value)} />
          <input type="text" id="cnpj" name="cnpj" placeholder="CNPJ" required value={cnpj} onChange={(e) => setCnpj(e.target.value)} />
        </>
      )}
        <input type="email" id="email" name="email" placeholder="E-mail" required value={email} onChange={(e) => setEmail(e.target.value)} />
        <input type="password" id="password" name="password" placeholder="Senha" required value={password} onChange={(e) => setPassword(e.target.value)} />
        {isSignUp2 && (
          <input type="password" id="confirm-password" name="confirm-password" placeholder="Confirme a senha" required value={confirmPassword} onChange={(e) => setConfirmPassword(e.target.value)} />
        )}
        <input className="secondary-button" type="submit" value={isSignUp2 ? "Cadastrar" : "Login"} />
        {error && <p className="error-message">{error}</p>}
      </form>
      <hr />
    </div>
  );
};

export default AuthModalEmpresa;

import axios from "axios";

const API_URL = process.env.REACT_APP_API_BASE_URL;

// Buscar vagas para uma empresa específica
export const fetchCompanyJobs = async (companyId, token) => {
  try {
    const response = await axios.get(`${API_URL}/vaga/empresa/${companyId}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    throw new Error("Erro ao buscar vagas da empresa: " + error.message);
  }
};

// Criar vaga
export const createJob = async (jobData, token) => {
  try {
    const response = await axios.post(`${API_URL}/vaga`, jobData, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    throw new Error("Erro ao criar vaga: " + error.message);
  }
};

// Editar vaga
export const updateJob = async (jobId, jobData, token) => {
  try {
    const response = await axios.put(`${API_URL}/vaga/${jobId}`, jobData, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    throw new Error("Erro ao atualizar vaga: " + error.message);
  }
};

// Excluir vaga
export const deleteJob = async (jobId, token) => {
  try {
    const response = await axios.delete(`${API_URL}/vaga/${jobId}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    throw new Error("Erro ao excluir vaga: " + error.message);
  }
};

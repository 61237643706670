import Nav from "../components/Nav";
import { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useAuth } from "../contexts/AuthContext";
import { createJob, updateJob, fetchJobById } from "../services/companyService";

const OnCadasterJob = () => {
  const navigate = useNavigate();
  const { jobId } = useParams(); // Captura o jobId da URL
  const { user } = useAuth(); // Pega os dados de autenticação do contexto
  const { authToken, userName } = user;

  const [formData, setFormData] = useState({
    titulo: "",
    descricao: "",
    habilidades_requeridas: [{ habilidade: "", proficiencia: 0 }], // requirements
    formacoes_requeridas: [],
    beneficios: "",
    salario: "",
    modalidade: "",
    outra_modalidade: "",
    cep: "",
    logradouro: "",
    numero: "",
    complemento: "",
    bairro: "",
    cidade: "",
    uf: "",
    pais: "",
    tipo_contrato: "",
    processo_seletivo: "",
    politica_diversidade: "",
    documentos_necessarios: "",
  });

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (jobId) {
      loadJobData(jobId);
    }
  }, [jobId]);

  const loadJobData = async (jobId) => {
    try {
      setLoading(true);
      const job = await fetchJobById(jobId, authToken);

      if (job) {

        // Atualizando o estado com os dados
        setFormData(job);
      } else {
        setError("Vaga não encontrada.");
      }
    } catch (error) {
      console.error("Erro ao carregar dados da vaga:", error);
      setError("Erro ao carregar os dados da vaga. Tente novamente mais tarde.");
    } finally {
      setLoading(false);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError(null);

    if (!formData.titulo || !formData.descricao || !formData.beneficios) {
      setError("Título, descrição e benefícios são obrigatórios.");
      setLoading(false);
      return;
    }

    
    formData.empresa_nome = userName;

    try {
      const action = jobId
        ? updateJob(jobId, formData, authToken)
        : createJob(formData, authToken);
      await action;

      navigate("/dashboard");
    } catch (error) {
      console.error("Erro ao salvar a vaga:", error);
      setError("Erro ao salvar a vaga.");
    } finally {
      setLoading(false);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleRequirementChange = (index, value) => {
    const newRequirements = [...formData.habilidades_requeridas];
    newRequirements[index].habilidade = value;
    setFormData((prev) => ({ ...prev, habilidades_requeridas: newRequirements }));
  };

  const handleProficiencyChange = (index, value) => {
    const newRequirements = [...formData.habilidades_requeridas];
    newRequirements[index].proficiencia = value;
    setFormData((prev) => ({ ...prev, habilidades_requeridas: newRequirements }));
  };

  const addRequirement = () => {
    setFormData((prev) => ({
      ...prev,
      habilidades_requeridas: [...prev.habilidades_requeridas, { habilidade_requerida: "", proficiency: 0 }],
    }));
  };

  const removeRequirement = (index) => {
    const newRequirements = formData.habilidades_requeridas.filter((_, i) => i !== index);
    setFormData((prev) => ({ ...prev, habilidades_requeridas: newRequirements }));
  };

  const buscarEnderecoPorCep = async (cep) => {
    try {
      const response = await fetch(`https://viacep.com.br/ws/${cep}/json/`);
      const data = await response.json();

      if (data.erro) {
        alert("CEP não encontrado");
        return;
      }

      setFormData((prev) => ({
        ...prev,
        cep: data.cep,
        logradouro: data.logradouro,
        complemento: data.complemento,
        bairro: data.bairro,
        cidade: data.localidade,
        uf: data.uf,
        pais: "Brasil",
      }));
    } catch (error) {
      console.error("Erro ao buscar o CEP:", error);
    }
  };

  const handleCepChange = (e) => {
    const cep = e.target.value;
    setFormData((prev) => ({ ...prev, cep: cep }));
    if (cep.length === 8) buscarEnderecoPorCep(cep);
  };






  const [editIndex, setEditIndex] = useState(null);

  const NivelEscolaridade = {
    VAZIO: "Selecione",
    ENSINO_FUNDAMENTAL: "Ensino Fundamental",
    ENSINO_MEDIO: "Ensino Médio",
    CURSO_TECNICO: "Curso Técnico",
    ENSINO_SUPERIOR: "Ensino Superior",
    POS_GRADUACAO: "Pós-Graduação",
    MESTRADO: "Mestrado",
    DOUTORADO: "Doutorado",
    POS_DOUTORADO: "Pós-Doutorado",
    CURSO_EXTENSAO: "Curso Profissionalizante",
  };

  const addFormacao = () => {
    const newFormacao = {
      nivel_escolaridade_requerido: formData.nivel_escolaridade_requerido,
      curso_requerido: formData.curso_requerido
    };

    setFormData((prevState) => {
      const updatedFormacoes = prevState.formacoes_requeridas || [];

      if (editIndex !== null) {
        updatedFormacoes[editIndex] = newFormacao;
        setEditIndex(null);
      } else {
        updatedFormacoes.push(newFormacao);
      }

      return { ...prevState, formacoes_requeridas: updatedFormacoes };
    });

    setFormData((prevState) => ({
      ...prevState,
      nivel_escolaridade_requerido: "",
      curso_requerido: ""
    }));
  };

  const editFormacao = (index, e) => {
    e.preventDefault();
    setFormData((prevState) => {
      const formacaoToEdit = prevState.formacoes_requeridas[index];

      return {
        ...prevState,
        nivel_escolaridade_requerido:
          formacaoToEdit.nivel_escolaridade_requerido,
        curso_requerido: formacaoToEdit.curso_requerido
      };
    });
    setEditIndex(index);
  };

  const deleteFormacao = (index) => {
    setFormData((prevState) => ({
      ...prevState,
      formacoes_requeridas: (prevState.formacoes_requeridas || []).filter((_, i) => i !== index),
    }));
  };






  return (
    <>
      <Nav setShowModal={() => { }} showModal={false} />
      <div className="onboarding">
        <h2>CRIE UMA VAGA DE EMPREGO</h2>
        <form onSubmit={handleSubmit}>
          <section>
            <label htmlFor="titulo">Título da Vaga</label>
            <input id="titulo" type="text" name="titulo" placeholder="Nome do cargo a ser preenchido" required={true} value={formData.titulo} onChange={handleChange} />

            <label htmlFor="descricao">Descrição da Vaga</label>
            <textarea id="descricao" name="descricao" placeholder="Detalhes sobre as responsabilidades, atividades, e requisitos do cargo" required={true} value={formData.descricao} onChange={handleChange} />

            {/* Requisitos */}
            <div className="requirements-header" style={{ display: "flex", alignItems: "center" }} >
              <label>Requisitos</label>
              <div className="info-wrapper">
                <i className="info-icon">ⓘ</i>
                <span className="tooltip-text">
                  Os requisitos consistem em texto e nível de proficiência (de 1 a 5 estrelas).
                </span>
              </div>
            </div>

            {formData.habilidades_requeridas.map((requirementObj, index) => (
              <div key={index} className="requirement-item">
                <input
                  type="text"
                  name={`habilidade-${index}`}
                  placeholder={`Habilidade ${index + 1}`}
                  value={requirementObj.habilidade}
                  onChange={(e) => handleRequirementChange(index, e.target.value)}
                  required={true}
                />
                <div className="stars">
                  {[1, 2, 3, 4, 5].map((level) => (
                    <button
                      key={level} type="button" onClick={() => handleProficiencyChange(index, level)}
                      style={{ color: requirementObj.proficiencia >= level ? "crimson" : "gray" }}>
                      ★
                    </button>
                  ))}
                </div>
                <button type="button" onClick={() => removeRequirement(index)} disabled={formData.habilidades_requeridas.length <= 1}>Remover</button>
              </div>
            ))}

            <button type="button" id="center-button" onClick={addRequirement}> {" "} Adicionar Requisito </button>
            <label htmlFor="beneficios">Benefícios</label>
            <textarea
              id="beneficios" name="beneficios" placeholder="Informações sobre os benefícios oferecidos" required={true} value={formData.beneficios} onChange={handleChange} />
            <label htmlFor="salario">Salário</label>
            <input id="salario" type="text" name="salario" placeholder="Faixa salarial ou salário específico, se aplicável" required={false} value={formData.salario} onChange={handleChange} />

            <label htmlFor="modalidade">Modalidade de Trabalho</label>
            <select id="modalidade" name="modalidade" required={true} value={formData.modalidade} onChange={handleChange}>
              <option value="">Selecione o formato de trabalho</option>
              <option value="presencial">Presencial</option>
              <option value="hibrido">Híbrido</option>
              <option value="homeOffice">Home Office</option>
              <option value="outro">Outro</option>
            </select>


            {formData.modalidade === "outro" && (<input type="text" name="outra_modalidade" placeholder="Descreva o formato" value={formData.outra_modalidade} onChange={handleChange} />)}

            <label htmlFor="endereco">Endereço</label>
            <div className="multiple-input-container">
              <input id="cep" type="text" name="cep" placeholder="CEP" value={formData.cep} onChange={handleCepChange} />
              <input id="logradouro" type="text" name="logradouro" placeholder="Logradouro" value={formData.logradouro} onChange={handleChange} />
              <input className="div-number" id="numero" type="number" name="numero" placeholder="Nº" required={false} value={formData.numero} onChange={handleChange} />
              <input id="complemento" type="text" name="complemento" placeholder="Complemento" value={formData.complemento} onChange={handleChange} />
            </div>

            <div className="multiple-input-container">
              <input id="bairro" type="text" name="bairro" placeholder="Bairro" value={formData.bairro} onChange={handleChange} />
              <input id="cidade" type="text" name="cidade" placeholder="Cidade" required={false} value={formData.cidade} onChange={handleChange} />
              <input id="uf" type="text" name="uf" placeholder="UF" value={formData.uf} onChange={handleChange} />
              <input id="pais" type="text" name="pais" placeholder="País" required={false} value={formData.pais} onChange={handleChange} />
            </div>

            <label htmlFor="tipo_contrato">Tipo de Contrato</label>
            <input id="tipo_contrato" type="text" name="tipo_contrato" placeholder="Especificação do tipo de contrato (CLT, PJ, estágio, temporário, etc.)" required={true} value={formData.tipo_contrato} onChange={handleChange} />
            {/* <label htmlFor="start_date">Data de Início</label>
            <input id="start_date" type="date" name="start_date" required={false} value={formData.start_date} onChange={handleChange} />
            <label htmlFor="application_deadline">Prazo de Candidatura</label>
            <input id="application_deadline" type="date" name="application_deadline" required={false} value={formData.application_deadline} onChange={handleChange} /> */}
            <label htmlFor="processo_seletivo"> Etapas do Processo Seletivo </label>
            <textarea id="processo_seletivo" name="processo_seletivo" placeholder="Descrição das etapas do processo seletivo (entrevistas, testes, dinâmicas)" required={false} value={formData.processo_seletivo} onChange={handleChange} />
            <label htmlFor="politica_diversidade"> Política de Diversidade e Inclusão </label>
            <textarea id="politica_diversidade" name="politica_diversidade" placeholder="Informações sobre políticas de diversidade e inclusão da empresa, se aplicável" value={formData.politica_diversidade} onChange={handleChange} />
            <label htmlFor="documentos_necessarios">Documentos Necessários</label>
            <textarea id="documentos_necessarios" name="documentos_necessarios" placeholder="Documentação que o candidato deve enviar (currículo, carta de apresentação, portfólio, etc.)" required={false} value={formData.documentos_necessarios} onChange={handleChange} />

            <label htmlFor="nivel_escolaridade_requerido">
              Nível de Escolaridade Requerido
            </label>
            <select id="nivel_escolaridade_requerido" name="nivel_escolaridade_requerido" value={formData.nivel_escolaridade_requerido || 'VAZIO'} onChange={handleChange}>
              <option value="VAZIO">Selecione</option>
              <option value="ENSINO_FUNDAMENTAL">Ensino Fundamental</option>
              <option value="ENSINO_MEDIO">Ensino Médio</option>
              <option value="CURSO_TECNICO">Curso Técnico</option>
              <option value="ENSINO_SUPERIOR">Ensino Superior</option>
              <option value="POS_GRADUACAO">Pós-Graduação</option>
              <option value="MESTRADO">Mestrado</option>
              <option value="DOUTORADO">Doutorado</option>
              <option value="POS_DOUTORADO">Pós-Doutorado</option>
              <option value="CURSO_EXTENSAO">Curso Profissionalizante</option>
            </select>
            <label htmlFor="curso_requerido">Curso</label>
            <input id="curso_requerido" type="text" name="curso_requerido" placeholder="Exemplo: Sistemas de Informação" value={formData.curso_requerido} onChange={handleChange} />
            <button type="button" onClick={addFormacao}> {""}
              {editIndex !== null
                ? " Atualizar Formação" : "Adicionar Formação"}
            </button>
            <ul>
              {(formData.formacoes_requeridas || []).length > 0 ? (
                formData.formacoes_requeridas.map((formacao, index) => {

                  return (
                    <li key={index} style={{ display: "flex", justifyContent: "space-between", alignItems: "center", }}>
                      <div>
                        <strong>{formacao.curso_requerido}</strong> -{" "}
                        {NivelEscolaridade[formacao.nivel_escolaridade_requerido]}{" "}
                      </div>
                      <div style={{ display: "flex", justifyContent: "flex-end", alignItems: "center", gap: "10px", }}>
                        <button onClick={(e) => editFormacao(index, e)}>
                          Editar
                        </button>
                        <button onClick={() => deleteFormacao(index)}>
                          Excluir
                        </button>
                      </div>
                    </li>
                  );
                })
              ) : (<li>Nenhuma formação cadastrada.</li>)}
            </ul>
            <br />
            <br />

            <button className="submit-forms" id="center-button" type="submit"> SALVAR </button>
          </section>
        </form>
      </div>
    </>
  );
};

export default OnCadasterJob;

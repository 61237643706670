// services/candidatoService.js
import axios from "axios";

const API_URL = process.env.REACT_APP_API_BASE_URL;

// Obter os dados do candidato
export const fetchCandidatoData = async (userId, token) => {
    const response = await axios.get(`${API_URL}/candidato/${userId}`, {
        headers: {
            Authorization: `Bearer ${token}`,  // Adiciona o token no cabeçalho
        },
    });
    return response.data;
};

// Atualizar os dados do candidato
export const updateCandidatoData = async (formData, token) => {
    const response = await axios.put(`${API_URL}/candidato/dados-gerais`, formData, {
        headers: {
            Authorization: `Bearer ${token}`,  // Adiciona o token no cabeçalho
        },
    });
    return response;
};

// ChatInput.js
import { useState } from 'react';
import { enviarMensagem } from "../services/matchesService";

const ChatInput = ({ user, clickedUser, getUsersMessages, getClickedUsersMessages }) => {
    const [textArea, setTextArea] = useState("");

    const sendMessage = async () => {
        if (!textArea) return;

        const payload = {
            id_origem: user.userId,
            correspondenciaId: clickedUser._id,
            mensagem: textArea
        };

        try {
            await enviarMensagem(payload);
            getUsersMessages();
            getClickedUsersMessages();
            setTextArea(""); 
        } catch (error) {
            console.error("Error sending message:", error);
        }
    };


   return (
        <div className="chat-input">
            <textarea 
                value={textArea}
                onChange={(e) => setTextArea(e.target.value)}
                placeholder="Digite sua mensagem..."
            />
            <button 
                className="secondary-button"
                onClick={sendMessage}
            >
                Enviar
            </button>
        </div>
    );
};

export default ChatInput;

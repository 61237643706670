import axios from "axios";

// URL base para as requisições
const API_URL = process.env.REACT_APP_API_BASE_URL;

// Função para buscar os matches confirmados de uma empresa
export const fetchMatchesForCompany = async (id_empresa) => {
  try {
    const response = await axios.get(`${API_URL}/correspondencia/empresa/${id_empresa}`);
    return response.data; // Retorna os matches da empresa
  } catch (error) {
    console.error("Erro ao buscar matches para a empresa:", error);
    throw error;
  }
};

// Função para buscar os matches confirmados de um candidato
export const fetchMatchesForUser = async (id_candidato) => {
  try {
    const response = await axios.get(`${API_URL}/correspondencia/candidato/${id_candidato}`);
    return response.data; 
  } catch (error) {
    console.error("Erro ao buscar matches para o candidato:", error);
    throw error;
  }
};

// Função para buscar dados do usuário (candidato)
export const fetchUserData = async (id_candidato) => {
  try {
    const response = await axios.get(`${API_URL}/user/users/${id_candidato}`);
    return response.data; // Retorna os dados do candidato
  } catch (error) {
    console.error("Erro ao buscar dados do candidato:", error);
    throw error;
  }
};

// Função para buscar dados da empresa
export const fetchCompanyData = async (id_empresa) => {
  try {
    const response = await axios.get(`${API_URL}/company/companies/${id_empresa}`);
    return response.data; // Retorna os dados da empresa
  } catch (error) {
    console.error("Erro ao buscar dados da empresa:", error);
    throw error;
  }
};

// Função principal que retorna os matches detalhados (empresa ou candidato)
export const fetchMatchesDetails = async (matches, userType) => {
  try {
    const detailedMatches = await Promise.all(
      matches.map(async (match) => {
        if (userType === "empresa") {
          const userData = await fetchUserData(match.id_user); // Busca os dados do candidato
          return { ...match, name: userData.nome_candidato, id: match.id_user };
        } else if (userType === "candidato") {
          const companyData = await fetchCompanyData(match.id_company); // Busca os dados da empresa
          return { ...match, name: companyData.nome_empresa, id: match.id_company };
        }
        return match;
      })
    );
    return detailedMatches;
  } catch (error) {
    console.error("Erro ao buscar detalhes dos matches:", error);
    throw error;
  }
};


export const enviarMensagem = async (payload) => {
  try {
    const response = await axios.post(`${API_URL}/correspondencia/enviar-mensagem`, payload);
    return response;
  } catch (error) {
    throw new Error("Erro ao aceitar conexão: " + error.message);
  }
};
import { useEffect, useState } from "react";
import { useLocation, Link, useNavigate } from "react-router-dom";
import { FaHouse } from "react-icons/fa6";
import { FaEdit, FaTimes } from "react-icons/fa";
import { useAuth } from "../contexts/AuthContext";
import { useJob } from "../contexts/JobContext";
import { fetchCompanyJobs, deleteJob } from "../services/navService";

const Nav = ({ setShowModal, showModal, setIsSignUp, setShowModal2, showModal2, setIsSignUp2, onSearch }) => {
  const { user, logout } = useAuth();
  const { userId, userType, authToken } = user;
  const location = useLocation();
  const navigate = useNavigate();
  const { selectedJobId, setSelectedJobId, selectedJobTitle, setSelectedJobTitle } = useJob();

  const [jobListings, setJobListings] = useState([]);
  const [showOpenPositions, setShowOpenPositions] = useState(false);




  const [searchQuery, setSearchQuery] = useState("");

  const handleSearch = (e) => {
    setSearchQuery(e.target.value);
    onSearch(e.target.value); // Chama o callback para o filtro
  };



  // Fetch company jobs
  useEffect(() => {
    const loadJobs = async () => {
      if (userType === "empresa" && authToken) {
        try {
          const jobs = await fetchCompanyJobs(userId, authToken);
          setJobListings(jobs);
        } catch (error) {
          console.error(error.message);
        }
      }
    };
    loadJobs();
  }, [userType, userId, authToken]);

  const handleSelectJob = (jobId, jobTitle) => {
    setSelectedJobId(jobId);
    setSelectedJobTitle(jobTitle);
  };

  const handleDeleteJob = async (jobId) => {
    try {
      await deleteJob(jobId, authToken);
      setJobListings((prev) => prev.filter((job) => job._id !== jobId));
      alert("Vaga excluida com sucesso!")
      if (jobId === selectedJobId) setSelectedJobId(null); // Limpa o ID no contexto
    } catch (error) {
      console.error(error.message);
    }
  };

  const toggleOpenPositions = () => setShowOpenPositions((prev) => !prev);

  const handleLogout = () => {
    logout();
    navigate("/");
  };

  const handleNavigateToCreateJob = () => navigate("/onCadasterJob");

  const handleClick = () => {
    setShowModal(true);
    setIsSignUp(false);
  };

  const handleClick2 = () => {
    setShowModal2(true);
    setIsSignUp2(false);
  };

  const handleEditJob = (jobId) => {
    navigate(`/onCadasterJob/${jobId}`);
  };

  const isMatchingPath = (path) => {
    const regex = new RegExp("^/onCadasterJob(/[^/]+)?$");
    return (
      path === "/dashboard" ||
      path === "/onBoarding" ||
      path === "/onBoardingEmpresa" ||
      regex.test(path)
    );
  };

  return (
    <nav>
      {location.pathname === "/" &&
        (!authToken || userType !== "candidato" || userType !== "empresa") && (
          <>
            <button className="primary-button-login-cand" onClick={handleClick} disabled={showModal} >
              Login Candidato
            </button>
            <button className="primary-button-login-emp" onClick={handleClick2} disabled={showModal2} >
              Login Empresa
            </button>
          </>
        )}

      {isMatchingPath(location.pathname) && (
        <>
          <div className="logo-container">
            <Link to="/dashboard" className="home-icon">
              <FaHouse />
            </Link>
            <input type="text" placeholder="Busque palavras chaves..." className="search-bar"
            value={searchQuery}
            onChange={handleSearch} 
            />
          </div>


          {selectedJobTitle && (
            <div className="selected-job">
              <strong>Vaga: </strong>{selectedJobTitle}
            </div>
          )}


          {(location.pathname === "/dashboard" ||
            location.pathname === "/onBoardingEmpresa" ||
            location.pathname === "/onCadasterJob" ||
            location.pathname.startsWith("/onCadasterJob/")) &&
            authToken &&
            userType === "empresa" && (
              <div className="dropdown">
                <button onClick={toggleOpenPositions} className="primary-button-ver-vaga" >
                  Gerenciar Vagas
                </button>

                {showOpenPositions && (

                  <ul onMouseLeave={toggleOpenPositions} className="open-positions-dropdown">
                    <button onClick={handleNavigateToCreateJob} className="primary-button-criar-vaga" >
                      Publicar Vaga
                    </button>
                    <hr className="hr-vagas"></hr>
                    <div className="scroll-list-vagas">
                      {jobListings.map((position) => (
                        <li key={position._id} 
                        onClick={() => handleSelectJob(position._id, position.titulo + " - " + position.modalidade)} className={selectedJobId === position._id ? "selected" : ""} >
                          <div className="position-info">
                            <strong>{position.titulo}</strong> -{" "}
                            {position.modalidade}
                          </div>
                          <div className="button-group">
                            <button
                              onClick={() => handleEditJob(position._id)} className="edit-button">
                              <FaEdit />
                            </button>

                            <button
                              onClick={(e) => { e.stopPropagation(); handleDeleteJob(position._id); }} className="delete-button" >
                              <FaTimes />
                            </button>

                          </div>
                        </li>
                      ))}
                    </div>
                  </ul>
                )}
              </div>
            )}

          {userType === "candidato" ? (
            <Link to="/onBoarding" className="primary-button-edit-profile">
              Editar Perfil {/*(candidato)*/}
            </Link>
          ) : (
            <Link to="/onBoardingEmpresa" className="primary-button-edit-profile" >
              Editar Perfil {/*(Empresa)*/}
            </Link>
          )}

          <button onClick={handleLogout} className="primary-button-logout">
            Logout
          </button>
        </>
      )}
    </nav>
  );
};

export default Nav;
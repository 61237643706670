import { useAuth } from "../contexts/AuthContext";
import { Navigate } from "react-router-dom";

const ProtectedRoute = ({ children, allowedUserTypes }) => {
  const { user } = useAuth();

  // Verifica se o usuário está autenticado
  if (!user.authToken) {
    return <Navigate to="/" />;
  }

  // Verifica se o tipo de usuário é permitido para a rota
  if (allowedUserTypes && !allowedUserTypes.includes(user.userType)) {
    return <Navigate to="/dashboard" />; // Redireciona para uma rota padrão
  }

  return children;
};

export default ProtectedRoute;

// ChatDisplay.js
import React, { useState, useEffect, useCallback } from 'react';
import Chat from './Chat';
import ChatInput from './ChatInput';

const ChatDisplay = ({ user, clickedUser }) => {
    const userId = user?.userId;
    const userName = user?.userName;
    const clickedUserId = clickedUser?.userData._id;
    const clickedUserName = clickedUser?.userData.nome;
    const [usersMessages, setUsersMessages] = useState([]);
    const [clickedUsersMessages, setClickedUsersMessages] = useState([]);

    const getUsersMessages = useCallback(() => {
        const userMessages = clickedUser?.mensagens?.filter(message => message.id_origem === userId);
        setUsersMessages(userMessages || []);
    }, [clickedUser, userId]);

    const getClickedUsersMessages = useCallback(() => {
        const clickedMessages = clickedUser?.mensagens?.filter(message => message.id_origem === clickedUserId);
        setClickedUsersMessages(clickedMessages || []);
    }, [clickedUser, clickedUserId]);

    useEffect(() => {
        if (user && clickedUser && clickedUser.mensagens) {
            getUsersMessages();
            getClickedUsersMessages();
        }
    }, [user, clickedUser, getUsersMessages, getClickedUsersMessages]);

    const messages = [];

    const namePartsUser = userName.split(" ");
    const namePartsUserFirstInitial = namePartsUser[0].charAt(0);
    const namePartsUserLastInitial = namePartsUser[namePartsUser.length - 1].charAt(0);

    usersMessages?.forEach(message => {
        const formattedMessage = {};
        formattedMessage['name'] = userName;
        formattedMessage['img'] = `${namePartsUserFirstInitial}${namePartsUserLastInitial}`;
        formattedMessage['message'] = message.mensagem;
        formattedMessage['timestamp'] = message.timestamp;
        formattedMessage['isSentByUser'] = 'sent';
        messages.push(formattedMessage);
    });

    const namePartsclickedUser = clickedUserName.split(" ");
    const namePartsclickedUserFirstInitial = namePartsclickedUser[0].charAt(0);
    const namePartsclickedUserLastInitial = namePartsclickedUser[namePartsclickedUser.length - 1].charAt(0);

    clickedUsersMessages?.forEach(message => {
        const formattedMessage = {};
        formattedMessage['name'] = clickedUserName;
        formattedMessage['img'] = `${namePartsclickedUserFirstInitial}${namePartsclickedUserLastInitial}`;
        formattedMessage['message'] = message.mensagem;
        formattedMessage['timestamp'] = message.timestamp;
        formattedMessage['isSentByUser'] = 'received';
        messages.push(formattedMessage);
    });

    const descendingOrderMessages = messages?.sort((a, b) => a.timestamp.localeCompare(b.timestamp));

    return (
        <> 
            <Chat descendingOrderMessages={descendingOrderMessages} />
            <ChatInput
                user={user}
                clickedUser={clickedUser}
                getUsersMessages={getUsersMessages}
                getClickedUsersMessages={getClickedUsersMessages}
            />
        </>
    );
};

export default ChatDisplay;

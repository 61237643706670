// services/companyService.js
import axios from "axios";

const API_URL = process.env.REACT_APP_API_BASE_URL;

// Obter os dados da empresa
export const fetchCompanyData = async (userId, token) => {
    const response = await axios.get(`${API_URL}/empresa/${userId}`, {
        headers: {
            Authorization: `Bearer ${token}`,  // Adiciona o token no cabeçalho
        },
    });
    return response.data;
};

// Atualizar os dados da empresa
export const updateCompanyData = async (formData, token) => {
    const response = await axios.put(`${API_URL}/empresa/dados-gerais`, formData, {
        headers: {
            Authorization: `Bearer ${token}`,  // Adiciona o token no cabeçalho
        },
    });
    return response;
};

// Criar nova vaga
export const createJob = async (formData, token) => {
    const response = await axios.post(`${API_URL}/vaga`, formData, {
        headers: {
            Authorization: `Bearer ${token}`,  // Adiciona o token no cabeçalho
        },
        withCredentials: true,
    });
    return response.data;
};

// Listar todas as vagas da empresa
export const fetchCompanyJobs = async (userId, token) => {
    const response = await axios.get(`${API_URL}/vaga/empresa/${userId}`, {
        headers: {
            Authorization: `Bearer ${token}`,  // Adiciona o token no cabeçalho
        },
    });
    return response.data;
};

export const fetchJobById = async (jobId, token) => {
    const response = await axios.get(`${API_URL}/vaga/${jobId}`, {
        headers: {
            Authorization: `Bearer ${token}`,  // Adiciona o token no cabeçalho
        },
    });
    return response.data;
};

// Deletar vaga
export const deleteJob = async (jobId, token) => {
    const response = await axios.delete(`${API_URL}/vaga/${jobId}`, {
        headers: {
            Authorization: `Bearer ${token}`,  // Adiciona o token no cabeçalho
        },
    });
    return response.data;
};

// Atualizar vaga
export const updateJob = async (jobId, formData, token) => {
    const response = await axios.put(`${API_URL}/vaga/${jobId}`, formData, {
        headers: {
            Authorization: `Bearer ${token}`,  // Adiciona o token no cabeçalho
        },
    });
    return response.data;
};

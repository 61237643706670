// MatchesDisplay.js
import React, { useEffect, useState, useCallback } from 'react';
import { useAuth } from "../contexts/AuthContext";  // Importando o hook de autenticação
import axios from 'axios';  // Importando o axios para fazer as requisições

// URL base para as requisições
const API_URL = process.env.REACT_APP_API_BASE_URL;

const MatchesDisplay = ({ setClickedUser }) => {
  const { user } = useAuth();  // Obtendo o usuário logado
  const { userId, userType } = user;  // Desestruturando o userId e userType

  const [matches, setMatches] = useState([]);  // Estado para armazenar os matches
  const [loading, setLoading] = useState(true);  // Estado para controle de carregamento

  const fetchMatches = useCallback(async () => {
    try {
      // Realizando a requisição para obter as correspondências confirmadas
      const response = await axios.get(`${API_URL}/correspondencia/correspondencias-confirmadas/${userType}/${userId}`);
      const confirmedMatches = response.data;

      // Agora, para cada correspondência, se for empresa, obtemos os dados do candidato
      const matchDetails = await Promise.all(confirmedMatches.map(async (match) => {
        let matchUserData = null;

        // Verifica se é empresa e, caso seja, busca os dados do candidato
        if (userType === "empresa") {
          const candidateResponse = await axios.get(`${API_URL}/candidato/${match.id_candidato}`);
          matchUserData = candidateResponse.data;  // Dados do candidato
        } else if (userType === "candidato") {
          const companyResponse = await axios.get(`${API_URL}/empresa/${match.id_empresa}`);
          matchUserData = companyResponse.data;  // Dados da empresa
        }

        return {
          ...match,
          userData: matchUserData,  // Adiciona os dados do candidato ou empresa à correspondência
        };
      }));

      setMatches(matchDetails);  // Atualiza o estado com os dados dos matches
    } catch (error) {
      console.error("Erro ao buscar os matches:", error);
    } finally {
      setLoading(false);  // Finaliza o carregamento
    }
  }, [userId, userType]);

  useEffect(() => {
    fetchMatches();
  }, [fetchMatches]);

  if (loading) return <div>Loading...</div>;  // Exibe "Loading..." enquanto os dados estão sendo carregados

  return (
    <div className="matches-display">
      {matches.length > 0 ? (
        matches.map((match) => {
          const nameParts = match.userData?.nome?.split(" ") || []; // Adiciona segurança ao split
          const firstInitial = nameParts[0]?.charAt(0) || ""; // Primeira letra do primeiro nome
          const lastInitial = nameParts[nameParts.length - 1]?.charAt(0) || ""; // Primeira letra do último nome

          return (
            <div
              key={match._id}  // Alterei para '_id' por ser o identificador único
              className="match-card"
              onClick={() => setClickedUser(match)}
            >
              <div className="match-initial">
                {firstInitial}{lastInitial} {/* Exibe a inicial do primeiro e último nome */}
              </div>
              <p className="match-name" title={match.userData?.nome}>
                {match.userData?.nome} {/* Classe para aplicar o estilo de truncamento */}
              </p>
            </div>
          );
        })
      ) : (
        <p>Não há correspondências.</p>  // Exibe uma mensagem caso não haja matches
      )}
    </div>
  );
};

export default MatchesDisplay;

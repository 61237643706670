import { createContext, useContext, useState, useEffect } from "react";
import { useCookies } from "react-cookie"; // Importando o hook useCookies

const JobContext = createContext();

export const JobProvider = ({ children }) => {
  const [cookies, setCookie] = useCookies(["SelectedJobId", "SelectedJobTitle"]);
  const [selectedJobId, setSelectedJobId] = useState(cookies.SelectedJobId || null);
  const [selectedJobTitle, setSelectedJobTitle] = useState(cookies.SelectedJobTitle || null);

  // Sempre que selectedJobId ou selectedJobTitle mudar, atualiza o cookie
  useEffect(() => {
    if (selectedJobId && selectedJobTitle) {
      setCookie("SelectedJobId", selectedJobId, { path: "/", secure: true });
      setCookie("SelectedJobTitle", selectedJobTitle, { path: "/", secure: true });
    }
  }, [selectedJobId, selectedJobTitle, setCookie]);

  return (
    <JobContext.Provider value={{ selectedJobId, setSelectedJobId, selectedJobTitle, setSelectedJobTitle }}>
      {children}
    </JobContext.Provider>
  );
};

export const useJob = () => useContext(JobContext);
